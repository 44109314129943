import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSponsoredById } from "../middleware/getSponsoredById";

const initialState = {
  loading: false,
  error: "",
  getSponsoredById: [],
};

const getSponsoredByIdSlice = createSlice({
  name: "getSponsoredById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSponsoredById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSponsoredById.fulfilled, (state, action) => {
      state.loading = false;
      state.getSponsoredById = action.payload;
    });
    builder.addCase(getSponsoredById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message || "Something went wrong";
    });
  },
});

export default getSponsoredByIdSlice.reducer;
