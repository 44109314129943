import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./feedbackPage.css";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getFeedback } from "../../services/redux/middleware/getFeedback";
import ScreenLoader from "../../Components/loader/screenLoader";

function FeedbackPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); 

  const feedbackData = useSelector((state) => state?.getFeedback?.getFeedbackData?.data || {});
  const { feedback = [], pagination = {} } = feedbackData;
  const { currentPage: serverPage, totalPages = 1 } = pagination;

  useEffect(() => {
    setLoading(true);
    dispatch(getFeedback({ page: currentPage }))
      .finally(() => setLoading(false));
  }, [dispatch, currentPage]);
console.log("feedback data is............", feedbackData)
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const navigateFeedback = (eventId) => {
    console.log("Navigating to event:", eventId);
    navigate(`/feedbackPizdeztable/${eventId}`);
  };
  

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="event__main__container">
        <div className="events-container">
          {feedback.map((row, index) => (
            <div
              key={index}
              onClick={() => {
                console.log("report id is", row?.eventId);
                navigateFeedback(row?.eventId);}} 
              className="event-card"
              style={{ backgroundImage: `url(${row?.coverEventImage})` }}
            >
              <p className="event-title">
                {row?.eventName ? row?.eventName : "-----"}
              </p>
            </div>
          ))}
        </div>
        {/* Pagination */}
        <Stack
          spacing={2}
          sx={{
            padding: "1rem",
            borderRadius: "8px",
          }}
        >
          <Pagination
            count={totalPages}
            page={serverPage || currentPage}
            onChange={handlePageChange}
            sx={{
              "& .MuiPagination-ul": {
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              },
            }}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
                sx={{
                  color: "#fff",
                  borderRadius: "10px",
                  border: "1px solid rgba(255, 255, 255, 0.5)",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#00d059",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              />
            )}
          />
        </Stack>
      </div>
    </>
  );
}

export default FeedbackPage;
