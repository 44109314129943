import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LuEye } from "react-icons/lu";
import { signIn } from "../../services/redux/middleware/signIn";
import "./Signed_In_Main.css";

const Signed_In_Main = () => {
  const [activeInput, setActiveInput] = useState(null);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleBorderChange = (inputName) => {
    setActiveInput(inputName);
  };
  const [passwordVisible, setPasswordVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    localStorage.setItem("selectedView","Simple User")
    e.preventDefault();
  
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    if (!email || !password) {
      toast.error("Please enter all fields");
      return;
    }
  
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      return;
    }
  
    try {
      const data = { email, password };
      console.log("Data sign in .....", data);
  
      const result = await dispatch(signIn(data));
  
      if (result.payload?.token) {
        toast.success("Login Successful");
        navigate("/users");
      } else {
        toast.error(result.payload?.message || "Login failed");
      }
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred");
    }
  };
  

  // Function to toggle password visibility

  return (
    <div className="signed_in_main_container_auth">
      <div className="natlogo-display-hide">
        <img src="/Images/Signed_in_main/natlogo.svg" alt="Logo" />
      </div>
      <div className="sign-in-main-content">
        <div className="sign-in-title-main-container">
          <h2 className="sign-in-title">
            Sign <span className="highlight">In</span>
          </h2>
        </div>
        <div className="email-and-password-main-container">
          <div class="gradient-border">
            <div class="content-container">
              <div
                className={`sign-in-middle-part ${
                  activeInput === "email" ? "active-border" : ""
                }`}
                onClick={() => handleBorderChange("email")}
              >
                <label htmlFor="email">EMAIL</label>
                <div className="email-input-container">
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="email-icon">
                    <img
                      src="/Images/Signed_in_main/email.svg"
                      alt="Email icon"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`sign-in-middle-part2 ${
              activeInput === "password" ? "active-border" : ""
            }`}
            onClick={() => handleBorderChange("password")}
          >
            <div className="auth-screen-password-part-container">
              <label className="password-label">PASSWORD</label>
              <div className="password-input-container">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="password-input"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="give-gap-to-lock-and-view-image">
                  <span
                    onClick={togglePasswordVisibility}
                    className="password-icon"
                  >
                    {passwordVisible ? (
                      <LuEye size={20} color="white" />
                    ) : (
                      <img
                        src="/Images/Signed_in_main/view.svg"
                        alt="Show password"
                      />
                    )}
                  </span>
                  <img
                    src="/Images/Signed_in_main/lock.svg"
                    alt="Lock icon"
                    className="lock-icon"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="Forgot-your-password-border-main">
            <p className="Forgot-your-password">Forgot your password?</p>
            <p className="Forgot-your-password-border-bottom"></p>
          </div> */}
        </div>
        <div className="log-in-button-bottom-in-sign-in-main">
          <button
            type="submit"
            onClick={handleSubmit}
            className="log-in-button-bottom-in-sign-in"
          >
            <p>Log In</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Signed_In_Main;
