import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import './CollectiblesOwned.css'
import "./Attendees_Hosted_Events.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getEventsAttendees } from "../../services/redux/middleware/getEvents";
import ScreenLoader from "../loader/screenLoader";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const Attendeed_Hosted_Events = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { eventId } = useParams();
  const dispatch = useDispatch();

  const [attendenceData, setattendenceData] = useState([]);
   const getattendenceData = useSelector((state) => state?.getEventsAttendees);
   const { data: users, totalPages } = getattendenceData?.getEventsAttendeesData?.data?.data || {};
    console.log(" page data is .....",getattendenceData?.getEventsAttendeesData?.data?.data )

  useEffect(() => {
    setLoading(true);
    console.log("currrrnet paguej hb", currentPage)
    dispatch(
      getEventsAttendees({ eventId, currentPage })
    ).finally(() => setLoading(false));
  }, [dispatch, eventId, currentPage]); 

  useEffect(() => {
    if (getattendenceData) {
      setattendenceData(getattendenceData?.getEventsAttendeesData?.data?.data?.attendees || []);
    }
  }, [getattendenceData]); 

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
console.log("attendees data is ....",getattendenceData )
  const handleExportCSV = () => {
    if (!attendenceData || attendenceData.length === 0) {
      toast.error("No data available to export!");
      return;
    }
    const csvData = [
      ["Profile", "Attendee Name", "Email", "Ticket Type", "Date"],
      ...attendenceData.map((row) => [
        row.profile || "N/A",
        row.attendeeName || "N/A",
        row.email || "N/A",
        row.ticketType || "N/A",
        row.date || "N/A",
      ]),
    ];

    const csvContent = csvData.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "attendees_hosted_events.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      
      <div className="personal-info-detail-container____________chnge">
        <div
          style={{ paddingLeft: "0px" }}
          className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image"
        >
          <img src="/Payout_Images/graphlogo.svg" onClick={goBack} />
          <h2>Attendees</h2>
        </div>
        <div className="export-button">
          <button onClick={handleExportCSV} className="explore___button1">
            Export
          </button>
        </div>
        <div className="CollectiblesOwned-to-give-width-attendeed">
          <Box>
            <Box
              sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
            ></Box>

            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.06)",
                overflowX: "auto",
              }}
            >
              <Table>
                <TableHead
                  sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                  className="to-apply-table-border-color"
                >
                  <TableRow>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "105px" }}
                      className="even-name-text-style-for"
                    >
                      Profile
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                      className="even-name-text-style-for"
                    >
                      Attendee Name
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                      className="even-name-text-style-for"
                    >
                      Email
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "220px",
                      }}
                      className="even-name-text-style-for"
                    >
                      Ticket Type
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "220px",
                      }}
                      className="even-name-text-style-for"
                    >
                      Date
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableRow
                  sx={{
                    height: "16px",
                    backgroundColor: "#030303",
                    width: "220px",
                  }}
                >
                  <TableCell colSpan={6}></TableCell>
                </TableRow>

                <TableBody className="for-to-give-the-padding">
                  {attendenceData?.length === 0 ? (
                    <TableRow
                      sx={{
                        height: "94px",
                        backgroundColor: "#0F0F0F",
                      }}
                    >
                      <TableCell
                        colSpan={6}
                        sx={{
                          textAlign: "center",
                          color: "#fff",
                          background:
                            "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                          borderBottom: "1px solid #292929",
                          borderRadius: "8px",
                          padding: "94px 337px",
                        }}
                      >
                        <Typography
                          className="empty_state_text"
                          variant="h6"
                          sx={{
                            marginBottom: "16px",
                            fontSize: "1rem",
                            fontWeight: 500,
                          }}
                        >
                          There’s No Event Tickets
                        </Typography>
                        {/* <button className="empty__state__button">
                          <div className="empty__state__button_text_container">
                            <img
                              src="/Images/dashboard/emptyIcon.svg"
                              alt="icon"
                              style={{
                                marginRight: "8px",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                            <span className="empty__state__button_text">
                              See What's On
                            </span>
                          </div>
                        </button> */}
                      </TableCell>
                    </TableRow>
                  ) : (
                    attendenceData?.map((row, index) => (
                      <TableRow
                        key={index}
                        className="main-even-name-text-style-for"
                      >
                        <TableCell
                          sx={{ borderBottom: "1px solid #292929" }}
                          className="main-even-name-text-style-for-padding-margin-name"
                        >
                          <img
                            src={
                              row?.profile? row.profile:"/Images/dashboard/naitram.png"
                            }
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%", 
                            }}
                          />
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "105px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-name"
                        >
                          {row?.attendeeName}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "220px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.email}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "220px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                        {row?.ticketType?row?.ticketType:"----"}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "220px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.date}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack
              spacing={2}
              sx={{
                backgroundColor: "#121212",
                padding: "1rem",
                borderRadius: "8px",
              }}
            >
              <Pagination
                count={totalPages || 1}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  "& .MuiPagination-ul": {
                    display: "flex",
                    gap: "10px",
                    justifyContent: "flex-end",
                  },
                }}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                    sx={{
                      color: "#fff",
                      borderRadius: "10px",
                      border: "1px solid rgba(255, 255, 255, 0.5)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#00d059",
                        color: "#fff",
                        borderRadius: "10px",
                      },
                    }}
                  />
                )}
              />
            </Stack>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Attendeed_Hosted_Events;
