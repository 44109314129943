import React from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import './Resaon_Report.css'

function Resaon_report() {
  return (
 <>
 <div className="main-popup-container">
    <div
      
      style={{ display: "flex", justifyContent:'center',alignItems:'center',height:'100vh'}}
    >
      <Modal.Dialog  >
        <Modal.Header>
          <Modal.Title>Reason For Report</Modal.Title>
          <img src="/Images/Resonreport-img/close.svg" />
        </Modal.Header>

        {/* <Modal.Body>
          <p>Modal body text goes here.</p>
        </Modal.Body> */}
        <div className="main-text-container">
          <div className="textpopup-container">
            <p className="text-popup">Sorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus. risus sem sollicitudin lacus, ut interdum tellus elit sed risus.</p>
          </div>
        </div>
      </Modal.Dialog>
    </div>
    </div>
 </>
  )
}

export default Resaon_report