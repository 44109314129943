import React, { useState } from "react";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  CartesianGrid,
} from "recharts";
import "./SalesAnalyst-Graph.css";
import { useEffect } from "react";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="tooltip-container">
        <p className="tooltip-label">{`$${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const SalesAnalystGraph = (salesGraph) => {
  const [grossSalesData, setGrossSalesData] = useState([]);
  useEffect(() => {
    const salesData =
      salesGraph?.salesGraph?.getLiveEvent?.data?.grossSalesByDay;
    if (salesData) {
      setGrossSalesData(salesData);
    }
    console.log("sales graph data is.....", salesData);
  }, [salesGraph]);
  const chartData = Object.entries(grossSalesData).map(([day, amount]) => ({
    name: day.slice(0, 3).toUpperCase(),
    value: parseInt(amount),
  }));

  return (
    <div className="sales-analyst-container">
      <div className="sales-header">
        <span className="sales-header-span">Gross Sales</span>
      </div>
      <ResponsiveContainer
        className="sales-header1"
        style={{ maxWidth: "856px" }}
        width="100%"
        height={400}
      >
        <ComposedChart
          data={chartData}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          {/* Gradient for Bar Chart */}
          <defs>
            <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#00D059" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#003D20" stopOpacity={0.2} />
            </linearGradient>
          </defs>
          {/* Add Grid */}
          <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
          {/* X Axis */}
          <XAxis
            dataKey="name"
            tick={{
              fontFamily: "Nexa-Bold",
              fontWeight: "400",
              fontSize: "30px",
              fill: "#FFFFFF",
              style: { color: "#FFFFFF" },
            }}
            tickLine={false}
            axisLine={false}
          />
          {/* Tooltip */}
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          {/* Bars */}
          <Bar dataKey="value" fill="url(#barGradient)" radius={[4, 4, 0, 0]}>
            <LabelList
              dataKey="value"
              position="top"
              style={{
                fill: "transparent",
                fontSize: 12,
                fontWeight: "bold",
              }}
            />
          </Bar>
          {/* Line Chart */}
          <Line
            type="monotone"
            dataKey="lineValue"
            stroke="#00D059"
            strokeWidth={5}
            dot={{ r: 0.05, fill: "transparent", background: "transparent" }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SalesAnalystGraph;
