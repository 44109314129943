import "./GetPaidRequest_After_Qa.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { getPayoutSummaryDetail } from "../../services/redux/middleware/getPayoutSummaryDetail";
import { getPayoutUserId } from "../../services/redux/middleware/getPayoutUserid";
import { approvePayout } from "../../services/redux/middleware/approvePayout";
import ScreenLoader from "../loader/screenLoader";
const GetPaidRequest_After_Qa = () => {
  const { payoutId } = useParams();
  const [userId, setUserId] = useState(null);
  const [approve, setApprove] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate2 = useNavigate();
  const goBack = () => {
    navigate2(-1);
  };
  console.log("EventId", payoutId);
  const dispatch = useDispatch();
  const paidSummaryData = useSelector(
    (state) => state?.getPayoutSummaryDetail?.payoutSummaryData?.data
  );

  console.log("paid summary data is...", paidSummaryData);

  useEffect(() => {
    setLoading(true);
    dispatch(getPayoutSummaryDetail({ eventId: payoutId }))
    .finally(() => setLoading(false)); 
  }, [dispatch]);

  useEffect(() => {
    if (paidSummaryData && paidSummaryData.event) {
      const extractedUserId = paidSummaryData?.event?.userId
      console.log("User ID:", extractedUserId);

      if (extractedUserId) {
        setUserId(extractedUserId);
      }
    }
  }, [paidSummaryData]);
  useEffect(() => {
    const id = paidSummaryData?.event?.id;
    if (id) {
      console.log("id is :", id);
    }
  }, [paidSummaryData]);

  useEffect(() => {
    if (userId) {
      dispatch(getPayoutUserId({ userId }));
    }
  }, [dispatch, userId]);

  const paidUserData = useSelector(
    (state) =>
      state?.getPayoutUserId?.getPayoutUserIdData?.data?.data?.withdrawalDetails
  );

  console.log("Paid summary detail data is...", paidUserData);

  const handleApprove = async () => {
    const id = paidSummaryData?.event?.id; 
    // if (!id) {
    //   toast.error("ID is missing");
    //   return;
    // }
  
    try {
      const data = { approve: 1, id }; 
      console.log("Approving payout with data:", data);
  
      const result = await dispatch(approvePayout(data));
  
      if (result?.payload?.status === 200) { 
        toast.success("Payout Approved");
      } else {
        toast.error("Payout Not Approved");
      }
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred");
    }
  };
  


  // let totalTicketCount = 0;
  // if (paidSummaryData?.ticketTypes) {
  //   totalTicketCount = paidSummaryData.ticketTypes.reduce((total, ticket) => {
  //     return total + ticket.count;
  //   }, 0);
  //   console.log("Total ticket count is:", totalTicketCount);
  // }
  const totalPaidAmount = useMemo(() => {
    return (
      paidSummaryData?.ticketTypes
        ?.filter((ticket) => ticket.selected === "paid")
        ?.reduce((sum, ticket) => {
          const price = parseFloat(ticket.price);
          return sum + ticket.count * price;
        }, 0) || 0
    );
  }, [paidSummaryData]);

  console.log("Total Paid Amount:", totalPaidAmount);

  const platformFees = 0.1;

  const payoutAvailable = totalPaidAmount - platformFees;

  const data = [
    { id: 1, item: "Tickets Sold", amount: `${totalPaidAmount}` },
    { id: 2, item: "Platform Fees", amount: `£${platformFees}` },
    {
      id: 3,
      item: "Payout Available",
      amount: `£${payoutAvailable.toFixed(2)}`,
    },
  ];

  console.log("Data:", data);
  return (
    <>
    {loading && <ScreenLoader />}
   
    <div className="paidRequests">
      <div className="Maindiv1">
        <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image">
          <img src="/Payout_Images/graphlogo.svg" onClick={goBack} />
          <h2>Amount</h2>
        </div>
        <div className="firstTable">
          <p className="FundRaied">Funds Raised</p>
          <p className="Value">{paidSummaryData?.funds?.totalFundsRaised}</p>
          <TableContainer
            style={{
              display: "flex",
            }}
            component={Paper}
            className="SalesAnalytics_table1___MAIN___11"
          >
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow className="SalesAnalytics_table1___MAIN">
                  <TableCell
                    sx={{ borderBottom: "none" }}
                    className="SalesAnalytics_table1___Ptags"
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "none" }}
                    className="SalesAnalytics_table1___Ptags"
                  >
                    Item
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "none" }}
                    className="SalesAnalytics_table1___Ptags"
                  >
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{
                      height: "16px",
                      backgroundColor: "transparent",
                      borderBottom: "none",
                      padding: "0px",
                    }}
                  ></TableCell>
                </TableRow>
                {data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        fontFamily: "Nexa",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20.3px",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                      className="SalesAnalytics_table1___Ptags2"
                      style={{ width: "70px" }}
                    >
                      {row.id}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        fontFamily: "Nexa",
                        fontWeight: "700",
                        fontSize: "14px",
                        lineHeight: "20.3px",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                      className="SalesAnalytics_table1___Ptags2"
                      style={{ width: "320px" }}
                    >
                      {row.item}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        fontFamily: "Nexa",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20.3px",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                      className="SalesAnalytics_table1___Ptags2"
                      style={{ width: "213px" }}
                    >
                      {row.amount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="maindiv2">
        <p className="withdrawldetails">Withdrawal Details</p>
        <div className="Table2">
          <div className="row1">
            <p className="bankName">Bank Name</p>
            <p className="bank">{paidUserData?.bankName?paidUserData?.bankName:"----"}</p>
          </div>
          <div className="row1">
            <p className="bankName">Title of Account</p>
            <p className="bank">{paidUserData?.accountTitle?paidUserData?.accountTitle:"----"}</p>
          </div>
          <div className="row1">
            <p className="bankName">Account Number</p>
            <p className="bank">{paidUserData?.IBAN?paidUserData?.IBAN:"----"}</p>
          </div>
          <div className="row1">
            <p className="bankName">Country/City</p>
            <p className="bank">{paidUserData?.country || paidUserData?.city || "----"}</p>
          </div>
        </div>
      </div>
      <div className="buttons">
        <button onClick={handleApprove} className="button1">Approve</button>
        <button className="button2">Deny</button>
      </div>
    </div>
    </>
  );
};
export default GetPaidRequest_After_Qa;
