import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFeedback } from "../middleware/getFeedback";

const initialState = {
  loading: false,
  error: "",
  getFeedbackData: [],
};
const getFeedbackSlice = createSlice({
  name: "getAllReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeedback.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFeedback.fulfilled, (state, action) => {
      state.loading = false;
      state.getFeedbackData = action.payload;
    });
    builder.addCase(getFeedback.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getFeedbackSlice.reducer;