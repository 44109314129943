import React from "react";
import { useEffect, useState } from "react";
import "./User_Scanner____Details.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getScannerDetail } from "../../services/redux/middleware/getScannerDetail";
import ScreenLoader from "../loader/screenLoader";
const User_Scanner____Details = () => {
  const navigate2 = useNavigate();
  const { id } = useParams();
  const goBack = () => {
    navigate2(-1);
  };
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const ScannerDetailData = useSelector(
    (state) => state?.getScannerDetail?.scannerData?.data?.data
  );
  console.log("scanner detail data is...", ScannerDetailData);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(getScannerDetail(id)).finally(() => setLoading(false));
    }
  }, [dispatch, id]);
  const handleDownloadCSV = () => {
    const headers = ["Name", "Email", "Password", "Scanned Event", "Scanned Date"];
    const rows = ScannerDetailData ? [
      [
        ScannerDetailData.name || "---",
        ScannerDetailData.email || "---",
        ScannerDetailData.password || "---",
        ScannerDetailData?.scannedEvents?.map(event => event.eventName).join(", ") || "---",
        ScannerDetailData.scannedDate || "---",
      ]
    ] : [];

    const csvContent = [
      headers.join(","),
      ...rows.map(row => row.join(","))
    ].join("\n");

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "scanner_details.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div
        className="just-for-to-query-use"
        style={{
          padding: " 75px 78px 75px 100px",
          maxWidth:'1113px'
        }}
      >
        <div className="User__detail_Scanner___Div1">
          <img
            src="/Payout_Images/userScannerDetailIcon.svg"
            alt=""
            onClick={goBack}
          />
          <p className="User__detail_Scanner___Div1__ptag">Scanner Details</p>
        </div>
        <div className="export-button">
        <button
          onClick={handleDownloadCSV}
          className="User__detail_Scanner___Btn"
        >
          Export
        </button>
        </div>
        <TableContainer
          component={Paper}
          className="Organizer_detail_Follower_Main"
        >
          <Table
            sx={{ minWidth: 900, maxWidth: 1123 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {/* <TableCell className="Organizer_detail_Follower_Main___Row">
                PFP
              </TableCell> */}
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Name
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Email
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Password
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Scanned Event
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Scanned Date
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{
                    height: "16px",
                    backgroundColor: "transparent",
                    borderBottom: "none",
                    padding: "0px",
                  }}
                ></TableCell>
              </TableRow>
              <TableRow className="rganizer_detail_Follower_Main___Body_Top">
                <TableCell className="Organizer_detail_Follower_Main___Body">
                  {ScannerDetailData?.name}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {ScannerDetailData?.email}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {ScannerDetailData?.password}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {ScannerDetailData?.totalScannedEvents
                    ? ScannerDetailData?.totalScannedEvents
                    : "0"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {ScannerDetailData?.latestScannedDate
                    ? ScannerDetailData?.latestScannedDate
                    : "----"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

      </div>
    </>
  );
};

export default User_Scanner____Details;
