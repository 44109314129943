import React, { useState, useEffect } from "react";
import "./SalesAnalytics_table2__2.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";

const SalesAnalytics_table2__2 = (saleData) => {
  const [ticketTypes, setTicketTypes] = useState();

  // Sample data as shown in the image
  const data = [
    { id: 1, ticketType: "Premium", Scan: 100, percentage: "90%" },
    { id: 2, ticketType: "Gold", Scan: 80, percentage: "78%" },
    { id: 3, ticketType: "Silver", Scan: 150, percentage: "120%" },
    { id: 4, ticketType: "Silver", Scan: 150, percentage: "120%" },
  ];

  const [isExpanded, setIsExpanded] = useState(false);
  const [totalScanCount, setTotalScanCount] = useState(0);
  const [totalSold, setTotalSold] = useState(0);

  const handleToggle = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  useEffect(() => {
    if (saleData?.salesData?.getLiveEvent?.data?.ticketTypes) {
      console.log(
        "Sales data for second table is .......",
        saleData.salesData.getLiveEvent.data.ticketTypes
      );
  
      const ticketTypes = saleData.salesData.getLiveEvent.data.ticketTypes;
      setTicketTypes(ticketTypes);
  
      const totalScanCount = ticketTypes.reduce(
        (acc, row) => acc + (Number(row?.scanCount) || 0),
        0 
      );
      console.log("Total count of scan count is:", totalScanCount);
  
      setTotalSold(totalScanCount);
    }
  }, [saleData]);
  

  
  return (
    <div className="SalesAnalytics_table1___main">
      <div className="SalesAnalytics_table1___main_Firstttt">
        <div className="SalesAnalytics_table1___main_Firstttt_1">
          <p className="SalesAnalytics_table1___main_Firstttt_1_p1">
          Scanned Tickets
          </p>
          <p className="SalesAnalytics_table1___main_Firstttt_1_p2">{totalSold}</p>
        </div>

        <div className="SalesAnalytics_table1___main_Firstttt_2">
          <img
            src="/Payout_Images/Favorite - Button.png"
            alt=".."
            className="Favorite-button-png"
          />
        </div>
      </div>

      <TableContainer
        style={{
          display: isExpanded ? "flex" : "none",
        }}
        component={Paper}
        className="SalesAnalytics_table1___MAIN___11"
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow className="SalesAnalytics_table1___MAIN">
              <TableCell className="SalesAnalytics_table1___Ptags">#</TableCell>
              <TableCell className="SalesAnalytics_table1___Ptags">
                Ticket Type
              </TableCell>
              <TableCell className="SalesAnalytics_table1___Ptags">
                Scan
              </TableCell>
              <TableCell className="SalesAnalytics_table1___Ptags">
                Percentage
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={4}
                style={{
                  height: "16px",
                  backgroundColor: "transparent",
                  borderBottom: "none",
                  padding: "0px",
                }}
              ></TableCell>
            </TableRow>

            {ticketTypes?.map((row,index) => (
              <TableRow key={row.id}>
                <TableCell
                  className="SalesAnalytics_table1___Ptags2"
                  style={{ width: "70px" }}
                >
                  {index+1}
                </TableCell>
                <TableCell
                  className="SalesAnalytics_table1___Ptags2"
                  style={{ width: "320px" }}
                >
                  {row?.selectedEventTicketType}
                </TableCell>
                <TableCell
                  className="SalesAnalytics_table1___Ptags2"
                  style={{ width: "213px" }}
                >
                  {row?.scanCount}
                </TableCell>
                <TableCell
                  className="SalesAnalytics_table1___Ptags2"
                  style={{ width: "233px" }}
                >
                  {((row?.scanCount/row?.originalNoOfTickets)*100).toFixed(2)}
                  %
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="expandimge____">
        <img
          src={
            isExpanded
              ? "/Payout_Images/minimize.svg"
              : "/Payout_Images/Back - Button.svg"
          }
          alt=""
          style={{ cursor: "pointer", position: "absolute" }}
          onClick={handleToggle}
        />
      </div>
    </div>
  );
};

export default SalesAnalytics_table2__2;
