import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../apiInterceptor';
import { API_URL } from '../../client';

export const getEventsAttendees = createAsyncThunk(
  'getEventsAttendees',
  async ({ eventId, currentPage }, { rejectWithValue }) => {
    try {
      console.log("Fetching event attendees for eventId:", eventId, " page:", currentPage);
      const res = await api.get(`${API_URL}/auth/getEventAttendees/${eventId}?page=${currentPage}`);
      return {
        status: res.status,
        data: res.data,
      };
    } catch (error) {
      return rejectWithValue({
        message: error?.response?.data?.error || 'Failed to fetch attendees',
        status: error?.response?.status || 500,
      });
    }
  }
);
