import React, { useState, useEffect } from "react";
import "./supportRequestDetail.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHelpCenterById } from "../../services/redux/middleware/getHelpCenterById";
import ScreenLoader from "../../Components/loader/screenLoader";
import { toast } from "react-toastify";

function SupportRequestDetail() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const HelpCenterByIdData = useSelector(
    (state) => state?.getHelpcenterById?.getHelpCenterByidData?.data?.data
  );

  console.log("ID from params:", id);
  console.log("Help Center by ID data:", HelpCenterByIdData);

  useEffect(() => {
    setLoading(true);
    if (id) {
      dispatch(getHelpCenterById({ id })).finally(() => {
        setLoading(false);
      });
    }
  }, [dispatch, id]);

  const navigate = useNavigate();
  const handleViewRequest = () => {
    navigate(-1);
  };

  const handleDownloadCSV = () => {
    if (!HelpCenterByIdData) {
      toast.error("No data available to export!");
      return;
    }

    const headers = ["Subject", "Description", "Attachments"];
    const rows = [
      [
        HelpCenterByIdData?.subject || "---",
        HelpCenterByIdData?.description || "---",
        (HelpCenterByIdData?.Attachments || []).join("; ") || "---",
      ],
    ];

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "support_request_detail.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="support-info-detail-container-new">
        <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image">
          <img
            onClick={handleViewRequest}
            src="/Payout_Images/graphlogo.svg"
            alt="Graph Logo"
          />
          <h2>Request</h2>
        </div>
        <div className="CollectiblesOwned-to-give-width-attendeed">
          <div className="export-button">
            <button onClick={handleDownloadCSV}>Export</button>
          </div>
        </div>
        <div className="support__detail__Subject_container">
          <div className="support__Detail__subject_container-1">
            <p className="subject__text__1">Subject</p>
            <p className="subject__text__description">
              {HelpCenterByIdData?.subject}
            </p>
          </div>
          <div className="support__Detail__subject_container-1">
            <p className="subject__text__1">Description</p>
            <p className="subject__text__description_detail">
              {HelpCenterByIdData?.description}
            </p>
          </div>
          <div className="support__Detail__subject_container-1">
            <p className="subject__text__1">Attachments</p>
            <div className="attachments__container">
              {HelpCenterByIdData?.Attachments?.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Attachment ${index + 1}`}
                  className="attachment__image"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportRequestDetail;
