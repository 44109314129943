import { configureStore } from "@reduxjs/toolkit";


import getallUser from "./reducer/getallUser";
import getEventsDetail from "./reducer/getEventsDetail";
import getClaimByUser from "./reducer/getClaimByUser";
import  getUserCollectibles  from "./reducer/getUserCollectibles" 
import  getChatPictures  from "./reducer/getChatPictures";
import getLikedEvents  from "./reducer/getLikedEvents";
import getOrganizationProfile  from "./reducer/getOrganizationProfile";
import getAllUserWithId from "./reducer/getAllUserById";
import  getOrganizer  from "./reducer/getOrganizer";
import getOrganizerById from "./reducer/getOrganizerById";
import getFollowerDetail from "./reducer/getFollowerDetail";
import hostedEvents from "./reducer/hostedEvents";
import getEventsAttendees from "./reducer/getEvents";
import getUserPayout from "./reducer/getUserpayout";
import getLiveEvent from "./reducer/getLiveEvent";
import getScanner from "./reducer/getScanner";
import  getLikedEventsOrganizer  from "./reducer/getLikedEventsOrganizer";
import getScannerDetail  from "./reducer/getScannerDetail";
import getKycApproval from "./reducer/getKycApproval";
import getKycRequest from "./reducer/getKycRequest";
import getKycById  from "./reducer/getKycById";
import getPaidRequest from "./reducer/getPaidRequest";
import getPayoutSummaryDetail from "./reducer/getPayoutSummaryDetail";
import getPayoutAccount from "./reducer/getPayoutAccount";
import getPayoutHistory from "./reducer/getPayoutHistory";
import getPayoutUserId from "./reducer/getPayoutUserId";
import getMrtReward from "./reducer/getMrtReward";
import getScannerById  from "./reducer/getScannerById";
import getReports from './reducer/getReports'
import getFeedback from "./reducer/getFeedback";
import getEventreportById from "./reducer/getEventreportById";
import getFeedBackEvents from "./reducer/getFeedBackEvents";
import getHelpCenter from "./reducer/getHelpCenter";
import getHelpcenterById from "./reducer/getHelpcenterById";
import getAllSponsers from "./reducer/getAllSponsers";
import getSponsoredById from "./reducer/getSponsoredById";
export const store = configureStore({
  reducer: {
  
    getAllUser:getallUser,
    getAllUserWithId:getAllUserWithId,
    getEventsDetail:getEventsDetail,
    getClaimByUser: getClaimByUser,
    getUserCollectibles:getUserCollectibles,
    getChatPictures:getChatPictures,
    getLikedEvents:getLikedEvents,
    getOrganizationProfile:getOrganizationProfile,
    getOrganizer:getOrganizer,
    getOrganizerById:getOrganizerById,
    getFollowerDetail:getFollowerDetail,
    hostedEvents:hostedEvents,
    getEventsAttendees:getEventsAttendees,
    getUserPayout:getUserPayout,
    getLiveEvent:getLiveEvent,
    getScanner:getScanner,
    getLikedEventsOrganizer:getLikedEventsOrganizer,
    getScannerDetail:getScannerDetail,
    getKycApproval:getKycApproval,
    getKycRequest:getKycRequest,
    getKycById:getKycById,
    getPaidRequest: getPaidRequest,
    getPayoutSummaryDetail:getPayoutSummaryDetail,
    getPayoutAccount:getPayoutAccount,
    getPayoutHistory:getPayoutHistory,
    getPayoutUserId:getPayoutUserId,
    getMrtReward:getMrtReward,
    getScannerById:getScannerById,
    getReports:getReports,
    getFeedback:getFeedback,
    getEventreportById:getEventreportById,
    getFeedBackEvents:getFeedBackEvents,
    getHelpCenter:getHelpCenter,
    getHelpcenterById:getHelpcenterById,
    getAllSponsers:getAllSponsers,
    getSponsoredById:getSponsoredById
  },
});