import React from "react";
import "./waitlistTable.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const data = [
  {
    eventName: "John Doe",
    ticketType: "akemiwrld@gmail.com",
    price: "11/12/23",
  },
  {
    eventName: "John Doe",
    ticketType: "akemiwrld@gmail.com",
    price: "11/12/23",
  },
  {
    eventName: "John Doe",
    ticketType: "akemiwrld@gmail.com",
    price: "11/12/23",
  },
  {
    eventName: "John Doe",
    ticketType: "akemiwrld@gmail.com",
    price: "11/12/23",
  },
  {
    eventName: "John Doe",
    ticketType: "akemiwrld@gmail.com",
    price: "11/12/23",
  },
  {
    eventName: "John Doe",
    ticketType: "akemiwrld@gmail.com",
    price: "11/12/23",
  },
  {
    eventName: "John Doe",
    ticketType: "akemiwrld@gmail.com",
    price: "11/12/23",
  },
  {
    eventName: "John Doe",
    ticketType: "akemiwrld@gmail.com",
    price: "11/12/23",
  },
  {
    eventName: "John Doe",
    ticketType: "akemiwrld@gmail.com",
    price: "11/12/23",
  },
  {
    eventName: "John Doe",
    ticketType: "akemiwrld@gmail.com",
    price: "11/12/23",
  },
  {
    eventName: "John Doe",
    ticketType: "akemiwrld@gmail.com",
    price: "11/12/23",
  },
  // Add more rows as necessary...
];

function Waitlist_detail_table() {
  const navigate = useNavigate();
  const handleClickIcon = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="personal-info-detail-container-new">
        <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image">
          <img onClick={handleClickIcon} src="/Payout_Images/graphlogo.svg" />
          <h2>PIZDEZ Womens Day Party 2024</h2>
        </div>
        <div className="CollectiblesOwned-to-give-width-attendeed">
          <div className="export-button">
            <button>Export</button>
          </div>
          <Box>
            <Box
              sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
            ></Box>

            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.06)",
                overflowX: "auto",
              }}
            >
              <Table>
                <TableHead
                  sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                  className="to-apply-table-border-color"
                >
                  <TableRow>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "105px" }}
                      className="even-name-text-style-for"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                      className="even-name-text-style-for"
                    >
                      Email
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                      className="even-name-text-style-for"
                    >
                      Date
                    </TableCell>

                    {/* <TableCell
                sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)"  , width:'220px'}}
                className="even-name-text-style-for"
              >
               Date
              </TableCell> */}
                  </TableRow>
                </TableHead>

                <TableRow
                  sx={{
                    height: "16px",
                    backgroundColor: "#030303",
                    width: "220px",
                  }}
                >
                  <TableCell colSpan={5}></TableCell>
                </TableRow>

                <TableBody className="for-to-give-the-padding">
                  {data.map((row, index) => (
                    <TableRow
                      key={index}
                      className="main-even-name-text-style-for"
                    >
                      <TableCell
                        sx={{ borderBottom: "1px solid #292929" }}
                        className="main-even-name-text-style-for-padding-margin-name"
                      >
                        {row.eventName}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "105px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-name_new"
                      >
                        {row.ticketType}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "220px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row.price}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
    </>
  );
}

export default Waitlist_detail_table;
