import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getEventReportDetail } from "../middleware/getEventReportById";

const initialState = {
  loading: false,
  error: "",
  getEventReportDetailData: [],
};
const getEventReportDetailSlice = createSlice({
  name: "getAllReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventReportDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEventReportDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.getEventReportDetailData = action.payload;
    });
    builder.addCase(getEventReportDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getEventReportDetailSlice.reducer;