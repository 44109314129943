import React, { useEffect, useState } from "react";
import "./supportRequest.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getHelpCenter } from "../../services/redux/middleware/getHelpCenter";
import ScreenLoader from "../../Components/loader/screenLoader";
import { toast } from "react-toastify";

function Support_Request_table() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const helpcenterData = useSelector(
    (state) => state?.getHelpCenter?.getHelpCenterData?.data?.data
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getHelpCenter()).finally(() => setLoading(false));
  }, [dispatch]);
  console.log("support table data is .............", helpcenterData);
  const handleViewRequest = (id) => {
    navigate(`/support_request_detail/${id}`);
  };

  const handleDownloadCSV = () => {
    if (!helpcenterData || helpcenterData.length === 0) {
      toast.error("No data available to export!");
      return;
    }

    const headers = ["Name", "Email", "Date", "Request"];
    const rows = helpcenterData.map((row) => [
      row?.user?.fullname || "---",
      row?.user?.email || "---",
      row?.createdAt.split("T")[0] || "---",
      "View Request",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "support_requests.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="support-info-detail-container-new1">
        <div className="CollectiblesOwned-to-give-width-attendeed">
          <div className="export-button">
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="primary"
              onClick={handleDownloadCSV}
            >
              Export
            </Button>
          </div>
          <Box>
            <Box
              sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
            ></Box>

            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.06)",
                overflowX: "auto",
              }}
            >
              <Table>
                <TableHead
                  sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                  className="to-apply-table-border-color"
                >
                  <TableRow>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "105px" }}
                      className="even-name-text-style-for"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                      className="even-name-text-style-for"
                    >
                      Email
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                      className="even-name-text-style-for"
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "220px",
                      }}
                      className="even-name-text-style-for"
                    >
                      Requests
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableRow
                  sx={{
                    height: "16px",
                    backgroundColor: "#030303",
                    width: "220px",
                  }}
                >
                  <TableCell colSpan={5}></TableCell>
                </TableRow>

                <TableBody className="for-to-give-the-padding">
                  {!helpcenterData || helpcenterData.length === 0 ? (
                    <TableRow
                      sx={{
                        height: "94px",
                        backgroundColor: "#0F0F0F",
                      }}
                    >
                      <TableCell
                        colSpan={4}
                        sx={{
                          textAlign: "center",
                          color: "#fff",
                          background:
                            "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                          borderBottom: "1px solid #292929",
                          borderRadius: "8px",
                          padding: "94px 337px",
                        }}
                      >
                        <Typography
                          className="empty_state_text"
                          variant="h6"
                          sx={{
                            marginBottom: "16px",
                            fontSize: "1rem",
                            fontWeight: 500,
                          }}
                        >
                          There’s No Support Requests
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    helpcenterData?.map((row, index) => (
                      <TableRow
                        key={index}
                        className="main-even-name-text-style-for"
                      >
                        <TableCell
                          sx={{ borderBottom: "1px solid #292929" }}
                          className="main-even-name-text-style-for-padding-margin-name"
                        >
                          {row?.user?.fullname ? row?.user?.fullname : "-----"}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "105px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-name_new"
                        >
                          {row?.user?.email ? row?.user?.email : "-----"}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "220px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.createdAt.split("T")[0]
                            ? row?.createdAt.split("T")[0]
                            : "-----"}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            console.log(
                              "id passed from support request is..........",
                              row?.id
                            );
                            handleViewRequest(row?.id);
                          }}
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "220px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type-newcolor"
                        >
                          View Request
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
    </>
  );
}

export default Support_Request_table;
