import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./User_Updated_Table.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserById } from "../../services/redux/middleware/getallUser";
import { getAllUserWithId } from "../../services/redux/middleware/getAllUserById";
import ScreenLoader from "../../Components/loader/screenLoader";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const User_Updated_Table = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const handleNavigate = (id) => {
    navigate(`/PersonalInfoDetail/${id}`);
  };
  const navigate2 = useNavigate();
  const goBack = () => {
    navigate2(-1);
  };
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const userData = useSelector((state) => state?.getAllUser);
  const { data: users, totalPages } = userData?.userData?.data?.data || {};
  console.log("received data is...", userData?.userData);

  useEffect(() => {
    setLoading(true);
    const datas={
      page:currentPage,
      search:searchQuery
    }
    dispatch(getAllUserById(datas)).finally(() => setLoading(false));
  }, [currentPage, dispatch,searchQuery]);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = userData?.userData?.data?.data?.users?.filter((user) =>
    user?.fullname?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleExport = () => {
    if (
      !userData?.userData?.data?.data?.users ||
      userData?.userData?.data?.data?.users.length === 0
    ) {
      toast.error("No data available to export!");
      return;
    }
    const headers = [
      "PFP",
      "Name",
      "Email",
      "Role",
      "Date Joined",
      "Tickets Bought",
      "Events Attended",
    ];

    const rows = userData?.userData?.data?.data?.users?.map((data) => [
      data?.profilePicture,
      data?.fullname || "----",
      data?.email || "----",
      data?.role || "----",
      data?.dateJoining || "----",
      data?.totalTicketsBought || "----",
      data?.eventsAttended || "----",filteredUsers
    ]);
    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent += headers.join(",") + "\n";
    rows.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "user_data.csv");
    link.click();
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="export-button">
          <button onClick={handleExport}>Export</button>
        </div>
      <div className="personal-info-detail-container____">
        <div className="personal__user__table_div">
          <div className="personal__user__table_div_1">
            <input
              className="search__user_input"
              type="text"
              placeholder="Search User"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <img
              style={{ height: "20px", width: "20px" }}
              src="/Images/dashboard/searchicon.svg"
              alt="search icon"
            />
          </div>
        </div>

        <Box>
          <Box sx={{ backgroundColor: "#000", mb: "16px" }}></Box>
          <TableContainer
            className="user_table_container"
            component={Paper}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                className="to-apply-table-border-color"
              >
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "85px" }}
                    className="even-name-text-style-for"
                  >
                    PFP
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" }}
                    className="even-name-text-style-for"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "188px" }}
                    className="even-name-text-style-for"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "195px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "105px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Date Joined
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "141px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Tickets Bought
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "141px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Events Attended
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "141px",
                      paddingRight: "20px",
                    }}
                    className="even-name-text-style-for"
                  >
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableRow
                sx={{
                  height: "16px",
                  backgroundColor: "#030303",
                  width: "84px",
                }}
              >
                <TableCell colSpan={8}></TableCell>
              </TableRow>
              <TableBody className="for-to-give-the-padding">
                {userData?.userData?.data?.data?.users?.map((data, index) => (
                  <TableRow
                    key={index}
                    className="main-even-name-text-style-for"
                  >
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "85px" }}
                      className="main-even-name-text-style-for-padding-margin-name"
                    >
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "1000px",
                        }}
                        src={
                          data.profilePicture || "/Images/dashboard/naitram.png"
                        }
                        alt="Profile"
                      />
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "188px", textTransform:"none !important"}}
                      className="main-even-name-text-style-for-padding-margin-name"
                    >
                      {data?.fullname
                        ? data?.fullname.length > 20
                          ? `${data?.fullname.slice(0, 20)}...`
                          : data?.fullname
                        : "----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "195px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {data?.email ? data?.email : "----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "105px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {data?.role ? data?.role : "----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "141px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {data?.dateJoining ? data?.dateJoining : "----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "141px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {data?.totalTicketsBought
                        ? data?.totalTicketsBought
                        : "0"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "141px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {data?.eventsAttended ? data?.eventsAttended : "0"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "84px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                      onClick={() => handleNavigate(data?.id)}
                    >
                      <img
                        src="/Images/User_Updated_table/eye_updated.svg"
                        alt="Eye View"
                        style={{ width: "24px", cursor: "pointer" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Stack
          spacing={2}
          sx={{
            backgroundColor: "#121212",
            padding: "1rem",
            borderRadius: "8px",
          }}
        >
          <Pagination
            count={totalPages || 1}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              "& .MuiPagination-ul": {
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              },
            }}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
                sx={{
                  color: "#fff",
                  borderRadius: "10px",
                  border: "1px solid rgba(255, 255, 255, 0.5)",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#00d059",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              />
            )}
          />
        </Stack>
       
      </div>
    </>
  );
};
export default User_Updated_Table;
