import React, { useEffect, useState } from "react";
import "./Organiser_Detail_Liked_Events.css";
import { TableCell, TableRow, Typography } from "@mui/material";
import { toast } from "react-toastify";
import ScreenLoader from "../loader/screenLoader";
const Organiser_Detail_Liked_Events = (likeEvent) => {
  const [loading, setLoading] = useState(true);
  const likeEventData =
    likeEvent?.likeEvent?.getLikedEventsData?.data?.likedEvents || [];

  const hasEvents = likeEventData.length > 0;
  useEffect(() => {
    if (likeEventData.length > 0) {
      setLoading(false);
    } else {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [likeEventData]);

  const convertToCSV = (likeEventData) => {
    const header = ["Event Name", "Start Time", "Image URL"];
    const rows = likeEventData.map((row) => [
      row?.eventDetails?.name,
      `${row?.eventDetails?.startTime.split("T")[0]} ${
        row?.eventDetails?.startTime.split("T")[1].split(".")[0]
      }`,
      row?.eventDetails?.mainEventImage,
    ]);

    const csvContent = [
      header.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    return csvContent;
  };

  const downloadCSV = () => {
    if (!likeEventData || likeEventData.length === 0) {
      toast.error("No data available to export!");
      return;
    }
    const csvData = convertToCSV(likeEventData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "liked_events.csv";
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="export-button">
        <button
          onClick={downloadCSV}
          className="Organizer_detail_Follower__Btn"
          style={{ marginTop: "0px", cssText: "margin-top: 0px !important;" }}
        >
          Export
        </button>
      </div>
      <div className="Organiser_Detail_Liked_Events_Main">
        {hasEvents ? (
          likeEventData.map((row, index) => (
            <div
              className="Organiser_Detail_Liked_Events_Main_1"
              style={{
                backgroundImage: `url(${row?.eventDetails?.coverEventImage})`,
              }}
            >
              <p className="Organiser_Detail_Liked_Events_Main_1_P1">
                {row?.eventDetails?.name}
              </p>
              <p className="Organiser_Detail_Liked_Events_Main_1_P2">
                {row?.eventDetails?.startTime.split("T")[0]}{" "}
                {row?.eventDetails?.startTime.split("T")[1].split(".")[0]}{" "}
              </p>
            </div>
          ))
        ) : (
          <TableRow>
            <div className="responsive-div">
              <Typography className="empty_state_text" variant="h6">
                There’s No Liked Events
              </Typography>
              {/* <button className="empty__state__button">
                <div className="empty__state__button_text_container">
                  <img src="/Images/dashboard/emptyIcon.svg" alt="icon" />
                  <p className="empty__state__button_text">See What's On</p>
                </div>
              
              </button> */}
            </div>
          </TableRow>
        )}
      </div>
    </>
  );
};

export default Organiser_Detail_Liked_Events;
