import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getScannerById } from "../../services/redux/middleware/getScannerById";
import ScreenLoader from "../loader/screenLoader";
import "./Organizer_Detail_Scanner_login.css";

const Organizer_Detail_Scanner_Login = () => {
  const [loading, setLoading] = useState(true);
  const [scannerDataState, setScannerDataState] = useState(null);
  const { organizerId } = useParams();
  const dispatch = useDispatch();
  const getScanner = useSelector(
    (state) => state?.getScannerById?.scannerData?.data?.data
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getScannerById({ userId: organizerId }));
  }, [dispatch, organizerId]);
  console.log("ccanner data organizer is ..........", getScanner)

  useEffect(() => {
    if (getScanner) {
      setScannerDataState(getScanner);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [getScanner]);

  const generateCSVData = () => {
    const headers = ["Name", "Email", "Scanned Event", "Following"];
    const rows = scannerDataState?.map((row) => [
      row?.name || "----",
      row?.email || "----",
      row?.scanCount || "----",
      row?.totalFollowers || "----",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    return csvContent;
  };

  // Download CSV
  const downloadCSV = () => {
    if (!scannerDataState || scannerDataState.length === 0) {
      toast.error("No data available to export!");
      return;
    }
    const csvData = generateCSVData();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "scanner_data.csv";
    link.click();
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div>
        <div className="export-button">
          <button
            style={{ marginTop: "0px", cssText: "margin-top: 0px !important;" }}
            onClick={downloadCSV}
            className="Organizer_detail_Follower__Btn"
          >
            Export
          </button>
        </div>
        <TableContainer
          component={Paper}
          className="Organizer_detail_Follower_Main"
        >
          <Table
            sx={{ minWidth: 750, maxWidth: 915 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Name
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Email
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Scanned Event
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Following
                </TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell
                colSpan={4}
                style={{
                  height: "16px",
                  backgroundColor: "transparent",
                  borderBottom: "none",
                  padding: "0px",
                }}
              ></TableCell>
            </TableRow>
            {!scannerDataState || scannerDataState.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      textAlign: "center",
                      color: "#fff",
                      padding: "94px 337px",
                      background:
                        "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                      borderBottom: "none",
                      borderRadius: "8px",
                      maxWidth: "904px",
                    }}
                  >
                    <Typography className="empty_state_text" variant="h6">
                      There’s No Scanner Login
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {scannerDataState?.map((row, index) => (
                  <TableRow
                    key={index}
                    className="Organizer_detail_Follower_Main___Body_Top"
                  >
                    <TableCell className="Organizer_detail_Follower_Main___Body">
                      {row?.name}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.email}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.scanCount}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.totalFollowers}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Organizer_Detail_Scanner_Login;
