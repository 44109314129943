import React, { useEffect, useState } from "react";
import "./New_Updated_Payout_GetPaid.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPaidRequest } from "../../services/redux/middleware/getPaidRequest";
import ScreenLoader from "../loader/screenLoader";
import { saveAs } from "file-saver";

const New_Updated_Payout_GetPaid = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleNavigate = (payoutId) => {
    navigate(`/GetPaidRequest_After_Qa/${payoutId}`);
  };

  const navigate2 = useNavigate();
  const goBack = () => {
    navigate2(-1);
  };

  const dispatch = useDispatch();
  const paidRequestData = useSelector(
    (state) => state?.getPaidRequest?.getPaidRequestData?.data?.data
  );

  console.log("paid request data is...", paidRequestData);

  useEffect(() => {
    setLoading(true);
    dispatch(getPaidRequest()).finally(() => setLoading(false));
  }, [dispatch]);

  const handleExportCSV = () => {
    if (!paidRequestData || paidRequestData.length === 0) {
      toast.error("No data available to export!");
      return;
    }
    const headers = [
      "Profile",
      "Name",
      "Email",
      "No of Events",
      "Amount",
      "Date",
    ];

    const rows = paidRequestData.map((row) => [
      row?.profile || "----",
      row?.name || "----",
      row?.email || "----",
      row?.noOfEvents || "----",
      row?.amountWithdrawn || "0",
      row?.date || "----",
    ]);

    const csvContent = [headers, ...rows]
      .map((row) => row.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "PaidRequestData.csv");
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="export__button__container_kyc">
        <button className="export-button-kyc" onClick={handleExportCSV}>
          Export
        </button>
      </div>
      <div className="new_updated_payout_to_give_margen_________">
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            overflowX: "auto",
          }}
          className="Organizer_detail_Follower_Main_____
"
        >
          <Table
            sx={{ minWidth: 1000, maxWidth: "100%" }}
            aria-label="simple table"
          >
            <TableHead sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}>
              <TableRow>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Profile
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Name
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Email
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  No of Events
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Amount
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Date
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={8}
                  style={{
                    height: "16px",
                    backgroundColor: "#030303",
                    borderBottom: "none",
                    padding: "0px",
                  }}
                ></TableCell>
              </TableRow>
              {!paidRequestData ? (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{
                      textAlign: "center",
                      color: "#fff",
                      padding: "94px 337px",
                      background:
                        "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                      borderBottom: "none",
                      borderRadius: "8px",
                      maxWidth: "904px",
                    }}
                  >
                    <Typography className="empty_state_text" variant="h6">
                      There’s No Get Paid Requests
                    </Typography>
                    {/* <button className="empty__state__button">
                      <div className="empty__state__button_text_container">
                        <img src="/Images/dashboard/emptyIcon.svg" alt="icon" />
                        <p className="empty__state__button_text">
                          See What's On
                        </p>
                      </div>
                    </button> */}
                  </TableCell>
                </TableRow>
              ) : (
                paidRequestData?.map((row, index) => (
                  <TableRow
                    key={index}
                    className="rganizer_detail_Follower_Main___Body_Top"
                  >
                    <TableCell className="Organizer_detail_Follower_Main___Body">
                      {row?.profile ? (
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "1000px",
                          }}
                          src={row?.profile}
                          alt="profile"
                        />
                      ) : (
                        <span className="no__image-placeholder">
                          No Image Available
                        </span>
                      )}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body">
                      {row?.name ? row?.name : "----"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.email ? row?.email : "----"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.noOfEvents ? row?.noOfEvents : "0"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.amountWithdrawn ? row?.amountWithdrawn : "0"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.date ? row?.date : "----"}
                    </TableCell>

                    <TableCell className="Organizer_detail_Follower_Main___Body">
                      <img
                        src="/Images/New_updated_get_piad/teer.svg"
                        alt=".."
                        onClick={() => handleNavigate(row?.eventId)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default New_Updated_Payout_GetPaid;
