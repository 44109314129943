import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import "./After___QA__PayoutHistory2.css";
import { useDispatch, useSelector } from "react-redux";
import { getPayoutAccount } from "../../services/redux/middleware/getPayOutAccount";
import ScreenLoader from "../loader/screenLoader";
const After___QA__PayoutHistory2 = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const paidAccountData = useSelector(
    (state) => state?.getPayoutAccount?.payoutSummaryData?.data?.data
  );
  console.log("paid account  data is...", paidAccountData);

  useEffect(() => {
    setLoading(true);
    dispatch(getPayoutAccount()).finally(() => setLoading(false));
  }, [dispatch]);
  const exportToCSV = () => {
    if (!paidAccountData || paidAccountData.length === 0) {
      toast.error("No data available to export!");
      return;
    }
    const csvHeaders = [
      "Organiser Name",
      "Bank/Wallet Name",
      "Acc No/Wallet Address",
      "Amount",
      "Chain/City",
    ];
    const csvRows = paidAccountData?.map((row) => [
      row?.organizerProfile?.name || "----",
      row?.bankName || "----",
      row?.IBAN || row?.walletAddress || "----",
      row?.amountWithdrawn || "----",
      row?.chain || row?.city || "----",
    ]);

    const csvContent = [
      csvHeaders.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Payout_Account.csv";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div>
        <div className="export__button__container_kyc">
          <button
            onClick={exportToCSV}
            className="export-button-kyc"
          >
            Export
          </button>
        </div>
        <TableContainer
          component={Paper}
          className="Organizer_detail_Follower_Main"
        >
          <Table
            sx={{ minWidth: 750, maxWidth: 1070 }}
            aria-label="simple table"
          >
            <TableHead style={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}>
              <TableRow>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Organiser Name
                </TableCell>
                <TableCell
                  style={{ whiteSpace: "nowrap" }}
                  className="Organizer_detail_Follower_Main___Row"
                >
                  Bank/Wallet Name
                </TableCell>
                <TableCell
                  style={{ whiteSpace: "nowrap" }}
                  className="Organizer_detail_Follower_Main___Row"
                >
                  Acc No/Wallet Address
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Amount
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Chain/City
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={5}
                  style={{
                    height: "16px",
                    backgroundColor: "transparent",
                    borderBottom: "none",
                    padding: "0px",
                  }}
                ></TableCell>
              </TableRow>
              {!paidAccountData ? (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{
                      textAlign: "center",
                      color: "#fff",
                      padding: "94px 337px",
                      background:
                        "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                      borderBottom: "none",
                      borderRadius: "8px",
                      maxWidth: "904px",
                    }}
                  >
                    <Typography className="empty_state_text" variant="h6">
                      There’s No Payout Accounts
                    </Typography>
                    {/* <button className="empty__state__button">
                      <div className="empty__state__button_text_container">
                        <img src="/Images/dashboard/emptyIcon.svg" alt="icon" />
                        <p className="empty__state__button_text">
                          See What's On
                        </p>
                      </div>
                    </button> */}
                  </TableCell>
                </TableRow>
              ) : (
                paidAccountData?.map((row, index) => (
                  <TableRow
                    key={index}
                    className="rganizer_detail_Follower_Main___Body_Top"
                  >
                    <TableCell className="Organizer_detail_Follower_Main___Body">
                      {row?.user?.fullname ? row?.user?.fullname : "----"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.bankName || row?.bankName || "----"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.IBAN || row?.walletAddress || "----"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.amountWithdrawn ? row?.amountWithdrawn : "0"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.chain || row?.city || "----"}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default After___QA__PayoutHistory2;
