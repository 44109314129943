import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./Updated_Scanner_Updated_Table.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getScanner } from "../../services/redux/middleware/getScanners";
import ScreenLoader from "../../Components/loader/screenLoader";

const Updated_Scanner_Updated_Table = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const handleNavigate = (id) => {
    navigate(`/User_Scanner____Details/${id}`);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const ScannerData = useSelector((state) => state?.getScanner);
  console.log("scanner data is...", ScannerData?.scannerData?.data?.data);

  useEffect(() => {
    setLoading(true);
    dispatch(getScanner()).finally(() => setLoading(false));
  }, [dispatch]);

  const handleDownloadCSV = () => {
    if (
      !ScannerData?.scannerData?.data?.data ||
      ScannerData?.scannerData?.data?.data.length === 0
    ) {
      toast.error("No data available to export!");
      return;
    }
    const headers = ["Name", "Email", "Role", "Date Joined", "Scan Tickets"];
    const rows = ScannerData?.scannerData?.data?.data || [];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [
        headers.join(","),
        ...rows.map((row) =>
          [
            row?.name || "---",
            row?.email || "---",
            row?.role || "---",
            row?.dateJoined?.split("T")[0] || "---",
            row?.scanCount || 0,
          ].join(",")
        ),
      ].join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "scanner_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      {loading && <ScreenLoader />}
      <div className="export-button">
            <button onClick={handleDownloadCSV}>Export</button>
          </div>
      <div className="personal-info-detail-container____">
        <Box>
          <Box
            sx={{ backgroundColor: "#000", mb: "16px", width: "1080px" }}
          ></Box>
          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                className="to-apply-table-border-color"
              >
                <TableRow>
                  {/* <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "85px" }}
                    className="even-name-text-style-for"
                  >
                    PFP
                  </TableCell> */}
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" }}
                    className="even-name-text-style-for"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "188px" }}
                    className="even-name-text-style-for"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "195px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "105px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Date Joined
                  </TableCell>
                  {/* <TableCell
                  sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)"  , width:'141px' }}
                  className="even-name-text-style-for"
                >
                 Tickets Bought
                </TableCell> */}
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "141px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Scan Tickets
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                     
                    }}
                    className="even-name-text-style-for"
                  >
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableRow
                sx={{
                  height: "16px",
                  backgroundColor: "#030303",
                  width: "84px",
                }}
              >
                <TableCell colSpan={8}></TableCell>
              </TableRow>
              <TableBody className="for-to-give-the-padding">
                {ScannerData?.scannerData?.data?.data?.map((row, index) => (
                  <TableRow
                    key={index}
                    className="main-even-name-text-style-for"
                  >
                    {/* <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "85px" }}
                      className="main-even-name-text-style-for-padding-margin-name"
                    >
                      <img
                        src={row.picture}
                        alt="Event"
                        style={{ width: "40px" }}
                      />
                    </TableCell> */}
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "188px",textTransform:'none !important' }}
                      className="main-even-name-text-style-for-padding-margin-name"
                    >
                      {row?.name}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "195px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.email}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "105px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.role}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "141px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.dateJoined.split("T")[0]}
                    </TableCell>
                    {/* <TableCell
                    sx={{ borderBottom: "1px solid #292929" , width:'141px' }}
                    className="main-even-name-text-style-for-padding-margin-type"
                  >
                    {row.date}
                  </TableCell> */}
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "141px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.scanCount}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "84px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      <img
                        onClick={() => {
                          console.log("scanner id is", row?.id);
                          handleNavigate(row?.id); 
                        }}
                        src="/Images/User_Updated_table/eye_updated.svg"
                        alt="Eye View"
                        style={{ width: "24px", cursor: "pointer" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </>
  );
};
export default Updated_Scanner_Updated_Table;
