import { Modal } from "react-bootstrap";
import "./reportModal.css";

function StaticExample({ show, handleClose, text }) {
  return (
    <>
      <Modal
        className="modal__feedback__container"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title className="feedback__title__heading">
              Reason For Report
            </Modal.Title>
            <img onClick={handleClose} src="/Images/Resonreport-img/close.svg" alt="Close" />
          </Modal.Header>

          <div className="main-text-container">
            <div className="textpopup-container">
              <p className="text-popup">{text}</p>
            </div>
          </div>
        </Modal.Dialog>
      </Modal>
    </>
  );
}

export default StaticExample;
