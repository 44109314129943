import React from "react";
import "./waitlist.css";
import { useNavigate } from "react-router-dom";
const Waitlist_table = () => {
  const data = [
    {
      bgimage: "/Payout_Images/img1.png",
    },
    {
      bgimage: "/Payout_Images/img2.png",
    },
    {
      bgimage: "/Payout_Images/img3.png",
    },
    {
      bgimage: "/Payout_Images/img1.png",
    },
    {
      bgimage: "/Payout_Images/img2.png",
    },
    {
      bgimage: "/Payout_Images/img3.png",
    },
  ];
  const navigate=useNavigate()
const handleClickCard = () => {
  navigate('/Waitlist_Detail');
};

  return (
    <>
    <div className="event__main__container">
      <div className="events-container">
        {data.map((row, index) => (
          <div onClick={handleClickCard}
            key={index}
            className="event-card"
            style={{ backgroundImage: `url(${row.bgimage})` }}
          >
            <p className="event-title">NAITRAM <br /> Launch <br /> Party 2024</p>
          </div>
        ))}
      </div>
      </div>
    </>
  );
};

export default Waitlist_table;