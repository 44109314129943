import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./MrtRewards.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreenLoader from "../../Components/loader/screenLoader";
import { getMrtReward } from "../../services/redux/middleware/getMrtReward";
const MrtRewards = ({ mrtReward, reward }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [mrtData, setMrtData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (reward?.getClaimUserData?.data?.history) {
      setLoading(false);
    } else {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [reward?.getClaimUserData?.data?.history]);

  console.log("my reward data is ", reward?.getClaimUserData?.data?.history);
  useEffect(() => {
    if (mrtReward?.getOrganizationProfileData?.data) {
      console.log(
        "MRT reward data is:",
        mrtReward?.getOrganizationProfileData?.data
      );
      setMrtData(mrtReward.getOrganizationProfileData.data);
    }
  }, [mrtReward]);
  const exportToCSV = () => {
    if (
      !reward?.getClaimUserData?.data?.history ||
      reward?.getClaimUserData?.data?.history.length === 0
    ) {
      toast.error("No data available to export!");
      return;
    }
  
    const csvHeaders = ["Reward Name", "MRT Reward", "Date", "Time"];
    const csvRows = reward?.getClaimUserData?.data?.history.map((row) => [
      row?.type || "----",
      row?.amount || "----",
      row?.date || "----",
      row?.time || "----",
    ]);
  
    const csvContent = [
      csvHeaders.join(","), 
      ...csvRows.map((row) => row.join(","))
    ].join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "MrtRewards_History.csv";
    link.click();
    URL.revokeObjectURL(url);
  };
  

  return (
    <div className="personal-info-detail-container-mrt-rewards">
      {loading ? (
        <ScreenLoader />
      ) : (
        <>
         <div className="export-button">
            <button onClick={exportToCSV}>Export</button>
          </div>
          <div className="mtr-owned-center-part">
            <p>MRT Owned</p>
            <h2 className="smjh-se-bahr-q">${mrtData?.totalThisWeek} MRT</h2>
          </div>

          <div className="box-main-both-head-and-body">
            <Box>
              <Box
                sx={{ height: "30px", backgroundColor: "#000", mb: "16px" }}
              ></Box>

              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.06)",
                  overflowX: "auto",
                }}
              >
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                    className="to-apply-table-border-color"
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "275px",
                        }}
                        className="even-name-text-style-for"
                      >
                        Reward Name
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(41, 41, 41, 1)",
                          width: "275px",
                        }}
                        className="even-name-text-style-for"
                      >
                        MRT Reward
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(41, 41, 41, 1)",
                          width: "275px",
                        }}
                        className="even-name-text-style-for"
                      >
                        Date
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(41, 41, 41, 1)",
                          width: "275px",
                        }}
                        className="even-name-text-style-for"
                      >
                        Time
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                    <TableCell colSpan={6}></TableCell>
                  </TableRow>

                  <TableBody>
                    {!reward?.getClaimUserData?.data?.history ? (
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          sx={{
                            textAlign: "center",
                            color: "#fff",
                            padding: "94px 337px",
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                            borderBottom: "none",
                            borderRadius: "8px",
                            maxWidth: "904px",
                          }}
                        >
                          <Typography className="empty_state_text" variant="h6">
                            There’s No MRT Rewards
                          </Typography>
                          {/* <button className="empty__state__button">
                            <div className="empty__state__button_text_container">
                              <img
                                src="/Images/dashboard/emptyIcon.svg"
                                alt="icon"
                              />
                              <p className="empty__state__button_text">
                                See What's On
                              </p>
                            </div>
                          </button> */}
                        </TableCell>
                      </TableRow>
                    ) : (
                      reward?.getClaimUserData?.data?.history?.map(
                        (row, index) => (
                          <TableRow
                            key={index}
                            className="main-even-name-text-style-for"
                          >
                            <TableCell
                              sx={{ borderBottom: "1px solid #292929" }}
                              className="main-even-name-text-style-for-padding-margin-name"
                            >
                              {row?.type ? row?.type : "----"}
                            </TableCell>

                            <TableCell
                              sx={{ borderBottom: "1px solid #292929" }}
                              className="main-even-name-text-style-for-padding-margin-type"
                            >
                              {row?.amount ? row?.amount : "----"}
                            </TableCell>
                            <TableCell
                              sx={{ borderBottom: "1px solid #292929" }}
                              className="main-even-name-text-style-for-padding-margin-type"
                            >
                              {row?.date ? row?.date : "----"}
                            </TableCell>

                            <TableCell
                              sx={{ borderBottom: "1px solid #292929" }}
                              className="main-even-name-text-style-for-padding-margin-type"
                            >
                              {row?.time ? row?.time : "----"}
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
         
        </>
      )}
    </div>
  );
};

export default MrtRewards;
