import React, { useEffect, useState } from "react";
import "./Organiser_Detail_HostedEvents_Sales_Analytics.css";
import SalesAnalytics_table1 from "../../Components/SalesAnalytics_table1/SalesAnalytics_table1";
import SalesAnalytics_table2__2 from "../../Components/SalesAnalytics_table2__/SalesAnalytics_table2__2";
import SalesAnalytics_table_3 from "../../Components/SalesAnalytics_table_3/SalesAnalytics_table_3";
import SalesChart from "../../Components/SalesAnalyst_Graph/SalesAnalyst_Graph";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLiveEvent } from "../../services/redux/middleware/getLiveEvent";
import { toast } from "react-toastify";
import ScreenLoader from "../../Components/loader/screenLoader";
const Organiser_Detail_HostedEvents_Sales_Analytics = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [salesGraph, setSalesGraph] = useState();
  const [saleData, setSalesData] = useState();
  const [ticketTypes, setTicketTypes] = useState([]);
  const [totalSold, setTotalSold] = useState(0);
  const [salesAnalyticsData, setsalesAnalyticsData] = useState([]);
  const getsalesAnalyticsData = useSelector((state) => state?.getLiveEvent);
  //
  useEffect(() => {
    setLoading(true);
    dispatch(getLiveEvent({ eventId: eventId })).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);

  useEffect(() => {
    if (getsalesAnalyticsData) {
      setsalesAnalyticsData(getsalesAnalyticsData);
    }
  }, [getsalesAnalyticsData]);

  console.log("getsalesAnalyticsData", salesAnalyticsData);
  useEffect(() => {
    if (salesAnalyticsData) {
      setSalesData(salesAnalyticsData);
    }
  });
  useEffect(() => {
    if (salesAnalyticsData) {
      setSalesGraph(salesAnalyticsData);
    }
  });
  useEffect(() => {
    if (saleData?.salesData?.getLiveEvent?.data?.ticketTypes) {
      console.log(
        "Sales data for second table is .......",
        saleData.salesData.getLiveEvent.data.ticketTypes
      );
  
      const ticketTypes = saleData.salesData.getLiveEvent.data.ticketTypes;
      setTicketTypes(ticketTypes);
  
      const totalScanCount = ticketTypes.reduce(
        (acc, row) => acc + (Number(row?.scanCount) || 0),
        0 
      );
      console.log("Total count of scan count isssssssss:", totalScanCount);
  
      setTotalSold(totalScanCount);
    }
  }, [saleData]);
  
  const exportToCSV = () => {
    const ticketTypes = salesAnalyticsData?.getLiveEvent?.data?.ticketTypes;
    const grossSalesByDay =
      salesAnalyticsData?.getLiveEvent?.data?.grossSalesByDay;
  
    const totalSales = ticketTypes
      ?.map((item) => item.count * item.price)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) || 0;
  
    const totalUserCount = ticketTypes
      ?.map((item) => item.userCount)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) || 0;
  
    console.log("Total Sales:", totalSales);
    console.log("Total User Count:", totalUserCount);
  

    if (ticketTypes.length === 0 && totalUserCount === 0) {
      toast.error("No data available to export!");
      return;
    }
  
    if (
      (!ticketTypes || ticketTypes.length === 0) &&
      (!grossSalesByDay || Object.keys(grossSalesByDay || {}).length === 0)
    ) {
      toast.error("No data available to export!");
      return;
    }
  
    let csvContent = "";
  
    if (ticketTypes && ticketTypes.length > 0) {
      const ticketHeaders = Object.keys(ticketTypes[0]).join(",") + "\n";
      const ticketRows = ticketTypes
        .map((item) =>
          Object.values(item)
            .map((value) =>
              typeof value === "string"
                ? `"${value.replace(/"/g, '""')}"`
                : value
            )
            .join(",")
        )
        .join("\n");
  
      csvContent += "Ticket Types\n" + ticketHeaders + ticketRows + "\n\n";
    }
  
    if (grossSalesByDay && Object.keys(grossSalesByDay).length > 0) {
      csvContent += "Gross Sales By Day\n";
      csvContent += "Day,Sales\n";
      const salesRows = Object.entries(grossSalesByDay)
        .map(([day, sales]) => `${day},${sales}`)
        .join("\n");
  
      csvContent += salesRows + "\n";
    }
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "sales_datadetail.csv";
    link.click();
  
    URL.revokeObjectURL(url);
  };
  
  const total = ticketTypes
  .map((item) => item.count * item.price)
  .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

const totalUserCount = ticketTypes
  .map((item) => item.userCount)
  .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

console.log("Total Salesssssssssssss:", ticketTypes);
console.log("Total User Countssssssss:", totalUserCount);
  return (
    <>
      {loading && <ScreenLoader />}
      <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main">
        <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image">
          <img src="/Payout_Images/graphlogo.svg" onClick={goBack} alt="" />
          <h2>Sales Analytics</h2>
        </div>
        <button
          style={{ marginTop: "0px", cssText: "margin-top: 0px !important;" }}
          onClick={exportToCSV}
          className="Organizer_detail_Follower__Btn"
        >
          Export
        </button>
        <SalesChart salesGraph={salesGraph} />
        <SalesAnalytics_table1 salesData={saleData} />
        <SalesAnalytics_table2__2 salesData={saleData} />
        <SalesAnalytics_table_3 salesData={saleData} />
      </div>
    </>
  );
};

export default Organiser_Detail_HostedEvents_Sales_Analytics;
