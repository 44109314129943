import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getHelpCenterById } from "../middleware/getHelpCenterById";

const initialState = {
  loading: false,
  error: "",
  getHelpCenterByidData : [],
};
const getHelpCenterByIdSlice = createSlice({
  name: "getHelpCenterById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHelpCenterById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHelpCenterById.fulfilled, (state, action) => {
      state.loading = false;
      state.getHelpCenterByidData = action.payload;
    });
    builder.addCase(getHelpCenterById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getHelpCenterByIdSlice.reducer;