import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const pushNotifications = createAsyncThunk("pushNotifications", async (data) => {
  try {
    console.log("Push notifications");
    const res = await api.post(`${API_URL}/auth/sendNotification`, data);
    return {
      status: res?.status,
      data: res?.data?.data,
      token: res?.data?.token,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const uploadImage = createAsyncThunk("imageUpload", async (image) => {
  try {
    console.log("Uploading image", image);
    const formData = new FormData();
    formData.append("file", image); 

    const response = await api.post(`${API_URL}/upload/uploadimage`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});
