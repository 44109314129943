import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./After___QA__PayoutHistory1.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPayoutHistory } from "../../services/redux/middleware/getPayoutHistory";
import ScreenLoader from "../loader/screenLoader";

const After___QA__PayoutHistory1 = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const paidHistoryData = useSelector(
    (state) => state?.getPayoutHistory?.getPayoutHistoryData?.data?.data
  );
  console.log("paid history  data is...", paidHistoryData);

  useEffect(() => {
    setLoading(true);
    dispatch(getPayoutHistory()).finally(() => setLoading(false));
  }, [dispatch]);

  const exportToCSV = () => {
    if (!paidHistoryData || paidHistoryData.length === 0) {
      toast.error("No data available to export!");
      return;
    }
    const csvHeaders = [
      "Name",
      "Amount",
      "Withdrawal Method",
      "Account Name",
      "Acc No/Wallet Address",
      "City/Chain",
      "Date",
    ];

    const csvRows = paidHistoryData?.map((row) => [
      row?.user?.fullname || "----",
      row?.amountWithdrawn || "----",
      row?.types || "----",
      row?.accountTitle || "----",
      row?.IBAN || row?.walletAddress || "----",
      row?.city || row?.chain || "----",
      row?.createdAt ? row?.createdAt.split("T")[0] : "----",
    ]);

    const csvContent = [
      csvHeaders.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Payout_History.csv";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div>
        <div className="export__button__container_kyc">
          <button onClick={exportToCSV} className="export-button-kyc">
            Export
          </button>
        </div>
        <TableContainer
          component={Paper}
          className="Organizer_detail_Follower_Main"
        >
          <Table
            sx={{ minWidth: 750, maxWidth: 1077 }}
            aria-label="simple table"
          >
            <TableHead style={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}>
              <TableRow>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Name
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Amount
                </TableCell>
                <TableCell
                  style={{ whiteSpace: "nowrap" }}
                  className="Organizer_detail_Follower_Main___Row"
                >
                  Withdrawal Method
                </TableCell>
                <TableCell
                  style={{ whiteSpace: "nowrap" }}
                  className="Organizer_detail_Follower_Main___Row"
                >
                  Account Name
                </TableCell>
                <TableCell
                  style={{ whiteSpace: "nowrap" }}
                  className="Organizer_detail_Follower_Main___Row"
                >
                  Acc No/ Wallet Address
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  City/Chain
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Date
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{
                    height: "16px",
                    backgroundColor: "transparent",
                    borderBottom: "none",
                    padding: "0px",
                  }}
                ></TableCell>
              </TableRow>
              {!paidHistoryData ? (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{
                      textAlign: "center",
                      color: "#fff",
                      padding: "94px 337px",
                      background:
                        "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                      borderBottom: "none",
                      borderRadius: "8px",
                      maxWidth: "904px",
                    }}
                  >
                    <Typography className="empty_state_text" variant="h6">
                      There’s No Payout History
                    </Typography>
                    {/* <button className="empty__state__button">
                      <div className="empty__state__button_text_container">
                        <img src="/Images/dashboard/emptyIcon.svg" alt="icon" />
                        <p className="empty__state__button_text">
                          See What's On
                        </p>
                      </div>
                    </button> */}
                  </TableCell>
                </TableRow>
              ) : (
                paidHistoryData?.map((row, index) => (
                  <TableRow
                    key={index}
                    className="rganizer_detail_Follower_Main___Body_Top"
                  >
                    <TableCell className="Organizer_detail_Follower_Main___Body">
                      {row?.user?.fullname ? row?.user?.fullname : "----"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.amountWithdrawn ? row?.amountWithdrawn : "0"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.types ? row?.types : "----"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.accountTitle ? row?.accountTitle : "----"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.IBAN || row?.walletAddress || "----"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.city || row?.chain || "----"}
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap" }}
                      className="Organizer_detail_Follower_Main___Body1"
                    >
                      {row?.createdAt.split("T")[0]
                        ? row?.createdAt.split("T")[0]
                        : "----"}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default After___QA__PayoutHistory1;
