import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { getChatPictures } from "../../services/redux/middleware/getChatPictures";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./PhotoRoll.css";
import ScreenLoader from "../../Components/loader/screenLoader";

const PhotoRoll = () => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { organizerId } = useParams();

  const photoRollData = useSelector(
    (state) => state?.getChatPictures?.getChatPicturesData?.data?.data
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getChatPictures({ userId: organizerId }));
  }, [dispatch, organizerId]);

  useEffect(() => {
    if (photoRollData) {
      setPhotos(photoRollData);
    }
    setLoading(false);
  }, [photoRollData]);
  console.log('photo data is ..........', photoRollData)

  const convertToCSV = (photos) => {
    const header = ["Index", "Picture URL"];
    const rows = photos.map((photo, index) => [index + 1, photo?.picture]);

    const csvContent = [
      header.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    return csvContent;
  };

  const downloadCSV = () => {
    if (!photos || photos.length === 0) {
      toast.error("No data available to export!");
      return;
    }
    const csvData = convertToCSV(photos);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "photos.csv";
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="export-button">
        <button onClick={downloadCSV}>Export</button>
      </div>
      <div className="personal-info-detail-container---roll">
        <div className="photoroll-photos-container">
          {photos?.length === 0 ? (
            <div className="responsive-div">
              <Typography className="empty_state_text" variant="h6">
                There’s No Photo Roll
              </Typography>
            </div>
          ) : (
            photos.map((photo, index) => (
              <img
                className="photo___image1"
                style={{ height: "140px", width: "140px", borderRadius: "8px" }}
                key={index}
                src={photo?.picture}
                alt={`Photo ${index + 1}`}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default PhotoRoll;
