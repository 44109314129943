import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Feedbackpopup.css";
import { getFeedBackEvents } from "../../services/redux/middleware/getFeedbackEvents";
import ScreenLoader from "../../Components/loader/screenLoader";
function StaticExample({ show, handleClose, text }) {
  return (
    <>

      <Modal
        className="modal__feedback__container"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title className="feedback__title__heading">
              Feedback
            </Modal.Title>
            <img
              onClick={handleClose}
              src="/Images/Resonreport-img/close.svg"
              alt="Close"
            />
          </Modal.Header>

          {/* <Modal.Body>
      <p>Modal body text goes here.</p>
    </Modal.Body> */}
          <div className="main-text-container">
            <div className="textpopup-container">
              <p className="text-popup">{text}</p>
            </div>
          </div>
        </Modal.Dialog>
      </Modal>
    </>
  );
}

export default StaticExample;
