import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllSponsores } from "../middleware/getAllSponsores";

const initialState = {
  loading: false,
  error: "",
  getAllSponsores: [],
};
const getAllSponsoresSlice = createSlice({
  name: "getAllSponsores",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllSponsores.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllSponsores.fulfilled, (state, action) => {
      state.loading = false;
      state.getAllSponsores = action.payload;
    });
    builder.addCase(getAllSponsores.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getAllSponsoresSlice.reducer;