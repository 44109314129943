import React, { useEffect, useState } from "react";
import "./Report.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getReports } from "../../services/redux/middleware/getReports";
import ScreenLoader from "../../Components/loader/screenLoader";

const OrganiserEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { reports, pagination } = useSelector((state) => {
    const data = state?.getReports?.getReportsData?.data || {};
    return {
      reports: data.reports || [],
      pagination: data.pagination || {},
    };
  });

  const { currentPage, totalPages } = pagination;

  useEffect(() => {
    setLoading(true);
    dispatch(getReports({ page: currentPage })).finally(() =>
      setLoading(false)
    );
  }, [dispatch, currentPage]);

  const handlePageChange = (event, value) => {
    dispatch(getReports({ page: value }));
  };

  const navigateReport = (eventId) => {
    console.log("Navigating to event:", eventId);
    navigate(`/Pizdeztable/${eventId}`);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="event__main__container">
        <div className="events-container">
          {reports.map((row, index) => (
            <div
              key={index}
              className="event-card"
              onClick={() => navigateReport(row?.eventId)}
              style={{ backgroundImage: `url(${row?.coverEventImage})` }}
            >
              <p className="event-title">{row?.eventName || "-----"}</p>
            </div>
          ))}
        </div>
        <Stack
          spacing={2}
          sx={{
            padding: "1rem",
            borderRadius: "8px",
          }}
        >
          <Pagination
            count={totalPages || 1}
            page={currentPage || 1}
            onChange={handlePageChange}
            sx={{
              "& .MuiPagination-ul": {
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              },
            }}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
                sx={{
                  color: "#fff",
                  borderRadius: "10px",
                  border: "1px solid rgba(255, 255, 255, 0.5)",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#00d059",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              />
            )}
          />
        </Stack>
      </div>
    </>
  );
};

export default OrganiserEvents;
