import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./Kyc_Updated_Request_Approval.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getKycRequest } from "../../services/redux/middleware/getKycRequest";
import ScreenLoader from "../loader/screenLoader";
const Kyc_Updated_Request_Approval = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const handleNavigate = (kycId) => {
    navigate(`/KycIndivialDetailTable/${kycId}`);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const kycRequestData = useSelector(
    (state) => state?.getKycRequest?.kycRequestData?.data?.data
  );
  console.log("kyc request data  is...", kycRequestData);

  useEffect(() => {
    setLoading(true);
    dispatch(getKycRequest()).finally(() => setLoading(false));
  }, [dispatch]);

  // Function to convert data to CSV format
  const convertToCSV = (data) => {
    const headers = ["Name", "Email", "DoB", "Role", "Website", "Date"];
    const rows = data.map((row) => [
      row?.user?.fullname || "----",
      row?.user?.email || "----",
      row?.Individual?.[0]?.DOB || "----",
      row?.userType || "----",
      row?.companyWebsite || "----",
      row?.createdAt.split("T")[0] || "----",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");
    return csvContent;
  };

  // Function to handle CSV download
  const handleExport = () => {
    if (!kycRequestData || kycRequestData.length === 0) {
      toast.error("No data available to export!");
      return;
    }

    const csvData = convertToCSV(kycRequestData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "KYC_Request_Data.csv";
    link.click();

    URL.revokeObjectURL(url);
  };
  return (
    <>
      {loading && <ScreenLoader />}
      <div className="kyc__main__table">
        <div className="export__button__container_kyc">
          <button className="export-button-kyc" onClick={handleExport}>
            Export
          </button>
        </div>
        <Box>
          <Box sx={{ backgroundColor: "#000", mb: "16px" }}></Box>
          <TableContainer
            className="Organizer_detail_Follower_Main_____for_bg"
            component={Paper}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                className="to-apply-table-border-color"
              >
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "188px" }}
                    className="even-name-text-style-for"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "200px" }}
                    className="even-name-text-style-for"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "131px" }}
                    className="even-name-text-style-for"
                  >
                    DOB
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "175px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Role
                  </TableCell> */}
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "175px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Website
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "130px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "104px",
                    }}
                    className="even-name-text-style-for"
                  >
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                <TableCell colSpan={8}></TableCell>
              </TableRow>
              <TableBody className="for-to-give-the-padding">
                {!kycRequestData ? (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      sx={{
                        textAlign: "center",
                        color: "#fff",
                        padding: "94px 337px",
                        background:
                          "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                        borderBottom: "none",
                        borderRadius: "8px",
                        maxWidth: "904px",
                      }}
                    >
                      <Typography className="empty_state_text" variant="h6">
                        There’s No Kyc Requests
                      </Typography>
                      {/* <button className="empty__state__button">
                        <div className="empty__state__button_text_container">
                          <img
                            src="/Images/dashboard/emptyIcon.svg"
                            alt="icon"
                          />
                          <p className="empty__state__button_text">
                            See What's On
                          </p>
                        </div>
                      </button> */}
                    </TableCell>
                  </TableRow>
                ) : (
                  kycRequestData?.map((row, index) => (
                    <TableRow
                      key={index}
                      className="main-even-name-text-style-for"
                    >
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "188px",
                          textTransform: "none",
                        }}
                        className="main-even-name-text-style-for-padding-margin-name"
                      >
                        {row?.user?.fullname ? row?.user?.fullname : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "200px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.user?.email ? row?.user?.email : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "130px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.Individual?.[0]?.DOB || "---"}
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "175px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.userType ? row?.userType : "----"}
                      </TableCell> */}
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "175px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.Individual?.[0]?.organizationWebsite || "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "130px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.createdAt.split("T")[0]}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "104px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        <img
                          onClick={() => {
                            console.log("id is", row?.userId);
                            handleNavigate(row?.userId);
                          }}
                          src="/Images/Kyc_Updated_ver/kyceye.svg"
                          alt="Eye View"
                          style={{ width: "24px", cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </>
  );
};
export default Kyc_Updated_Request_Approval;
