import "./KycIndivialDetail.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./KycIndivialDetail.css";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "react-toastify";
import RequestS_Details_Representative from "../RequestS_Details_Representative/RequestS_Details_Representative";
import RequestS_Details_Owners from "../RequestS_Details_Owners/RequestS_Details_Owners";
import RequestS_Details_Executive from "../RequestS_Details_Executive/RequestS_Details_Executive";
import { useDispatch, useSelector } from "react-redux";
import { getKycById } from "../../services/redux/middleware/getKycById";
import { approveStatus } from "../../services/redux/middleware/approveStatus";
import ScreenLoader from "../loader/screenLoader";
function KycIndivialDetailTable() {
  const navigate = useNavigate();
  const { kycId } = useParams();
  const location = useLocation(); // To track the current route
  const [userId, setUserId] = useState(null);
  const [act, setact] = useState("A");
  const handlebtnclick = (active) => {
    setact(active);
  };
  const navigate2 = useNavigate();
  const goBack = () => {
    navigate2(-1);
  };
  // Function to check if the current path is active
  const isActive = (path) => location.pathname === path;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const kycDataById = useSelector(
    (state) => state?.getKycById?.kycRequestData?.data?.data
  );
  console.log("kyc approval data by id is...", kycDataById);
  useEffect(() => {
    setLoading(true);
    dispatch(getKycById({ userId: kycId })).finally(() => setLoading(false));
  }, [dispatch]);
  useEffect(() => {
    setLoading(true);
    dispatch(getKycById({ userId: kycId })).finally(() => setLoading(false));
  }, [dispatch]);
  useEffect(() => {
    if (kycDataById && Array.isArray(kycDataById)) {
      kycDataById.forEach((record, index) => {
        console.log(
          `User ID from KYC data at index ${index}: `,
          record?.userId
        );

        if (record?.Representative&& Array.isArray(record?.Representative)) {
          record.Representative.forEach((individual) => {
            console.log(`DOB for reprsenstative : `, individual?.DOB);
          });
        }
      });
    }
  }, [kycDataById]);
 
  const handleApprove = async () => {
    if (kycDataById && Array.isArray(kycDataById)) {
      try {
        let approvalSuccess = false; 
  
        for (const record of kycDataById) {
          if (record?.userId) {
            const data = { userId: record.userId, approved: 1 };
            console.log("Approving status with data:", data);
  
            const result = await dispatch(approveStatus(data));
            console.log("Dispatch result:", result);
  
            if (result.type === "approveStatus/fulfilled") {
              if (result.payload?.status === 201) {
                approvalSuccess = true; 
              }
            }
          }
        }
  
        if (approvalSuccess) {
          toast.success("Approved successfully");
        } else {
          toast.success("Approved successfully");
        }
  
      } catch (error) {
        console.error("Error during approval:", error);
        toast.error(error.message || "An unexpected error occurred");
      }
    } else {
      toast.error("No KYC data available for approval");
    }
  };
 
  
  const handleDeny = async () => {
    toast.error("Kyc details not approved");
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="personal-info-detail-container___________newchnge">
        <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image__owners">
          <img src="/Payout_Images/graphlogo.svg" onClick={goBack} />
          <h2>KYC Details</h2>
        </div>
        <div className="top-buttons___new_try">
          <button
            style={{
              background:
                act === "A"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "A" ? "#00A849" : "#FFFFFF",
              border:
                act === "A"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/personal-information") ? "active" : ""}
            onClick={() => handlebtnclick("A")}
          >
            Individual
          </button>
          <button
            style={{
              background:
                act === "B"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "B" ? "#00A849" : "#FFFFFF",
              border:
                act === "B"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/event-tickets") ? "active" : ""}
            onClick={() => handlebtnclick("B")}
          >
            Business
          </button>
          <button
            style={{
              background:
                act === "C"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "C" ? "#00A849" : "#FFFFFF",
              border:
                act === "C"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/nft-rewards") ? "active" : ""}
            onClick={() => handlebtnclick("C")}
          >
            Representative
          </button>
          <button
            style={{
              background:
                act === "D"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "D" ? "#00A849" : "#FFFFFF",
              border:
                act === "D"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/collectibles-owned") ? "active" : ""}
            onClick={() => handlebtnclick("D")}
          >
            Owners
          </button>
          <button
            style={{
              background:
                act === "E"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "E" ? "#00A849" : "#FFFFFF",
              border:
                act === "E"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/photo-roll") ? "active" : ""}
            onClick={() => handlebtnclick("E")}
          >
            Executive
          </button>
        </div>

        {act === "A" && (
          
          <div className="given-to-the-width-to-all">
              <div className="RequestS_Details_Representative_divlast">
              <button
                onClick={handleApprove}
                className="RequestS_Details_Representative_divlastBtn1"
              >
                Aprove
              </button>
              <button
                onClick={handleDeny}
                className="RequestS_Details_Representative_divlastBtn2"
              >
                Deny
              </button>
            </div>
            <Box>
              <Box
                sx={{
                  height: "50px",
                  backgroundColor: "#000",
                  mb: "16px",
                  width: "1250px",
                }}
              ></Box>

              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.06)",
                  overflowX: "auto",
                }}
              >
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                    className="to-apply-table-border-color"
                  >
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: "1px solid #292929" }}
                        className="even-name-text-style-for"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid #292929" }}
                        className="even-name-text-style-for"
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        DOB
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Website
                      </TableCell>

                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Address
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Town/City
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Country
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* Spacer row for gap */}
                  <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                    <TableCell colSpan={7}></TableCell>
                  </TableRow>
                  <TableBody>
                    {!kycDataById ||
                    !Array.isArray(kycDataById) ||
                    kycDataById.length === 0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          sx={{
                            textAlign: "center",
                            color: "#fff",
                            padding: "94px 337px",
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                            borderBottom: "none",
                            borderRadius: "8px",
                            maxWidth: "904px",
                          }}
                        >
                          <Typography className="empty_state_text" variant="h6">
                            There’s No Individual
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      kycDataById.map((record, index) => {
                        
                        if (
                          record?.Individual &&
                          Array.isArray(record?.Individual) &&
                          record.Individual.length > 0
                        ) {
                          return record.Individual.map(
                            (individual, individualIndex) => {
                              return (
                                <TableRow
                                  key={`${index}-${individualIndex}`}
                                  className="main-even-name-text-style-for"
                                >
                                  <TableCell
                                    sx={{
                                      borderBottom: "1px solid #292929",
                                      width: "275px",
                                    }}
                                    className="main-even-name-text-style-for-padding-margin-name"
                                  >
                                    {`${individual.FirstName || "----"} ${
                                      individual.LastName || "----"
                                    }`.trim()}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "1px solid #292929",
                                      width: "275px",
                                    }}
                                    className="main-even-name-text-style-for-padding-margin-type"
                                  >
                                    {individual.Email || "----"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "1px solid #292929",
                                      width: "275px",
                                    }}
                                    className="main-even-name-text-style-for-padding-margin-type"
                                  >
                                    {individual.DOB || "----"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "1px solid #292929",
                                      width: "275px",
                                    }}
                                    className="main-even-name-text-style-for-padding-margin-type"
                                  >
                                    {individual.organizationWebsite || "----"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "1px solid #292929",
                                      width: "275px",
                                    }}
                                    className="main-even-name-text-style-for-padding-margin-type"
                                  >
                                    {individual.Address1 || "----"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "1px solid #292929",
                                      width: "275px",
                                    }}
                                    className="main-even-name-text-style-for-padding-margin-type"
                                  >
                                    {individual.City || "----"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "1px solid #292929",
                                      width: "275px",
                                    }}
                                    className="main-even-name-text-style-for-padding-margin-type"
                                  >
                                    {individual.Country || "----"}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          );
                        }
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          
          </div>
        )}
        {act === "B" && (
          <div className="given-to-the-width-to-all">
             <div className="RequestS_Details_Representative_divlast">
              <button
                onClick={handleApprove}
                className="RequestS_Details_Representative_divlastBtn1"
              >
                Aprove
              </button>
              <button
                onClick={handleDeny}
                className="RequestS_Details_Representative_divlastBtn2"
              >
                Deny
              </button>
            </div>
            <Box>
              <Box
                sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
              ></Box>

              <TableContainer className="business__table__container"
                component={Paper}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.06)",
                  overflowX: "auto",
                  width: "100%",
                  maxWidth: "1250px",
                }}
              >
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                    className="to-apply-table-border-color"
                  >
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: "1px solid #292929" }}
                        className="even-name-text-style-for"
                      >
                        Company Name
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid #292929" }}
                        className="even-name-text-style-for"
                      >
                        Business Type
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        UTR
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Website
                      </TableCell>

                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Address
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Town/City
                      </TableCell>
                      <TableCell
                        style={{ paddingRight: "100px !important" }}
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for1"
                      >
                        Country
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* Spacer row for gap */}
                  <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                    <TableCell colSpan={7}></TableCell>
                  </TableRow>

                  <TableBody>
                    {!kycDataById || kycDataById.length === 0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          sx={{
                            textAlign: "center",
                            color: "#fff",
                            padding: "94px 337px",
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                            borderBottom: "none",
                            borderRadius: "8px",
                            maxWidth: "904px",
                          }}
                        >
                          <Typography className="empty_state_text" variant="h6">
                            There’s No Business
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      kycDataById.map((record, index) => {
                        console.log("Processing record:", record); 

                       
                        if (
                          record?.Business &&
                          Array.isArray(record.Business) &&
                          record.Business.length > 0
                        ) {
                          return record.Business.map(
                            (individual, individualIndex) => (
                              <TableRow
                                key={individualIndex}
                                className="main-even-name-text-style-for"
                              >
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid #292929",
                                    width: "275px",
                                  }}
                                  className="main-even-name-text-style-for-padding-margin-name"
                                >
                                  {individual?.companyName || "----"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid #292929",
                                    width: "275px",
                                  }}
                                  className="main-even-name-text-style-for-padding-margin-type"
                                >
                                  {individual?.BusinessType || "----"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid #292929",
                                    width: "275px",
                                  }}
                                  className="main-even-name-text-style-for-padding-margin-type"
                                >
                                  {individual?.UTR || "----"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid #292929",
                                    width: "275px",
                                  }}
                                  className="main-even-name-text-style-for-padding-margin-type"
                                >
                                  {individual?.companyWebsite || "----"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid #292929",
                                    width: "275px",
                                  }}
                                  className="main-even-name-text-style-for-padding-margin-type"
                                >
                                  {individual?.Address1 || "----"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid #292929",
                                    width: "275px",
                                  }}
                                  className="main-even-name-text-style-for-padding-margin-type"
                                >
                                  {individual?.City || "----"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid #292929",
                                    width: "275px",
                                  }}
                                  className="main-even-name-text-style-for-padding-margin-type"
                                >
                                  {individual?.Country || "----"}
                                </TableCell>
                              </TableRow>
                            )
                          );
                        } else {
                         
                          return null;
                        }
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
           
          </div>
        )}

        {act === "C" && <RequestS_Details_Representative />}
        {act === "D" && <RequestS_Details_Owners />}
        {act === "E" && <RequestS_Details_Executive />}
      </div>
    </>
  );
}

export default KycIndivialDetailTable;
