import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "./sidebar.css";
import User from "../../Assets/Dashboard/profile.svg";
import PushIcon from "../../Assets/Dashboard/pushicon.svg";
import KYC from "../../Assets/Dashboard/KYCApprovals.svg";
import payout from "../../Assets/Dashboard/Payout.svg";
import report from '../../Assets/Dashboard/reportIcon.svg'
import feedback from '../../Assets/Dashboard/feedbackIcon.svg'
import supportIcon from '../../Assets/Dashboard/support.svg'
import payoutAccount from "../../Assets/Dashboard/PayoutAccounts.svg";
import payoutHistory from "../../Assets/Dashboard/PayOutHistory.svg";
import PayoutGetPaid from "../../Assets/Dashboard/GetPaidRequest.svg";
import logo from "../../Assets/Dashboard/NaitriumLOGO.svg";
import ArrowDown from "../../Assets/Dashboard/arrow-down.svg";
import logout from "../../Assets/Dashboard/logout.svg";
import { useNavigate, useLocation } from "react-router-dom";
import Approval from "../../Assets/Dashboard/KYC.svg";
import Sponsored from "../../Assets/Dashboard/sponsoredicon.svg"
import Waitlist from "../../Assets/Dashboard/waitlist.svg"
const drawerWidth = 264;

export default function Sidebar({ window, children }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [openSubMenu, setOpenSubMenu] = useState(null); // Track which submenu is open
  const [selectedSubItem, setSelectedSubItem] = useState(null); // Track which subitem is selected

  const [isClosing, setIsClosing] = React.useState(false);
  const [payoutOpen, setPayoutOpen] = React.useState(false);
  const [title, setTitle] = React.useState("Dashboard");
  const [selectedRoute, setSelectedRoute] = useState("Users");
  const toggleSubMenu = (menu) => {
    // If the clicked menu is already open, close it; otherwise, open the clicked menu
    setOpenSubMenu((prev) => (prev === menu ? null : menu));
  };

  const handleSubMenuClick = (menu) => {
    setOpenSubMenu((prev) => (prev === menu ? "" : menu)); // Toggle submenu
  };
  function handleLoginClick() {
    navigate("/");
  }
  useEffect(() => {
    // When route or title changes, ensure they sync with `localStorage`
    const savedRoute = localStorage.getItem("selectedRoute");
    const savedTitle = localStorage.getItem("selectedTitle");
    if (savedRoute && savedTitle) {
      setSelectedRoute(savedRoute);
      setTitle(savedTitle);
    }
  }, []);

  const handleItemClick = (route, itemText) => {
    console.log("this is route",route)
    setSelectedRoute(route);
    setTitle(itemText);
    localStorage.setItem("selectedRoute", route);
    localStorage.setItem("selectedTitle", itemText);
    localStorage.setItem("selectedView","Simple User")
    navigate(route);
    setMobileOpen(false);
  };

  const handleSubItemClick = (route, subItemText) => {
    setSelectedSubItem(route);
    setSelectedRoute(route);
    if (route === "KycAndAproval") {
      setTitle("KYC & Approval");
    } else {
      setTitle(subItemText);
    }

    localStorage.setItem("selectedRoute", route);
    localStorage.setItem("selectedTitle", subItemText);
    navigate(route);
    setMobileOpen(false);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const sidebarItems = [
    { text: "Users", icon: User, route: "/users" },
    {
      text: "KYC",
      icon: KYC,
      route: "/KycAndAproval",
      subItems: [
        { text: " Approvals", icon: Approval, route: "/KycAndAproval" }, // Update icon and route as needed
        {
          text: "Requests",
          icon: Approval,
          route: "/Kyc_Updated_Request_Approval",
        }, // Update icon and route as needed
      ],
    },
    {
      text: "Payout",
      icon: payout,
      route: "/New_Updated_Payout_GetPaid",

      subItems: [
        {
          text: "Get Paid Requests",
          icon: PayoutGetPaid,
          route: "/New_Updated_Payout_GetPaid",
        },
        {
          text: "Payout Accounts",
          icon: payoutAccount,
          route: "/After___QA__PayoutHistory2",
        },
        {
          text: "Payout History",
          icon: payoutHistory,
          route: "/After___QA__PayoutHistory1",
        },
      ],
    },
    { text: "Push Notification", icon: PushIcon, route: "/pushNotifications" },
    { text: "Report", icon: report, route: "/report" },
    { text: "Feedback", icon: feedback, route: "/feedbackPage" },
    { text: "Support Requests", icon: supportIcon, route: "/Support_request" },
    { text: "Get Sponsored", icon: Sponsored, route: "/Sponsores" },
    { text: "Waitlist", icon: Waitlist, route: "/Wait_List" },
    
  ];

  const drawer = (
    <div className="drawer">
      <img className="Logo" src={logo} alt="logo" />
      <div className="side__drawer__div">
      <div className="List">
        <List sx={{ width: "100%", paddingTop: "0", paddingBottom: "0" }}>
          {sidebarItems.map((item) => (
            <React.Fragment key={item.text}>
              <ListItem disablePadding>
                <ListItemButton
                  className={
                    selectedRoute === item.route || openSubMenu === item.text
                      ? "sidebarButtons selectedButton"
                      : "sidebarButtons"
                  }
                  sx={{
                    display: "flex",
                    gap: "12px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    borderRadius: "8px",
                    // border:
                    //   selectedRoute === item.route || openSubMenu === item.text
                    //     ? "2px solid rgba(0, 208, 89, 1)"
                    //     : "none",
                    // backgroundColor:
                    //   selectedRoute === item.route || openSubMenu === item.text
                    //     ? "rgba(0, 208, 89, 0.1)"
                    //     : "transparent",
                  }}
                  onClick={
                   
                      () => {
                          setOpenSubMenu(""); // Collapse any open submenu
                          handleItemClick(item.route, item.text);
                          if( item.subItems){
                            setOpenSubMenu(
                              openSubMenu === item.text ? "" : item.text
                            )
                          }
                        }
                  }
                >
                  <ListItemIcon sx={{ minWidth: "24px" }}>
                    <img
                      src={item.icon}
                      alt={`${item.text} icon`}
                      style={{
                        width: "24px",
                        height: "24px",
                        filter:
                          selectedRoute === item.route ||
                          openSubMenu === item.text
                            ? "invert(49%) sepia(83%) saturate(1839%) hue-rotate(102deg) brightness(97%) contrast(101%)"
                            : "none",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      fontFamily: "Nexa-Bold",
                      color:
                        selectedRoute === item.route ||
                        openSubMenu === item.text
                          ? "rgba(0, 208, 89, 1)"
                          : "rgba(255, 255, 255, 1)",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  />
                  {item.subItems && (
                    <img
                      src={ArrowDown}
                      alt="Arrow Down"
                      style={{
                        width: "12px",
                        height: "12px",
                        transform:
                          openSubMenu === item.text
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
              {item.subItems && openSubMenu === item.text && (
                <List sx={{ paddingLeft: "32px" }}>
                  {item.subItems.map((subItem) => (
                    <ListItem disablePadding key={subItem.text}>
                      <ListItemButton
                        className={selectedRoute === subItem.route ? "" : ""}
                        sx={{
                          display: "flex",
                          gap: "12px",
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          borderRadius: "8px",
                          border: selectedRoute === subItem.route ? "" : "none",
                          backgroundColor:
                            selectedRoute === subItem.route
                              ? ""
                              : "transparent",
                        }}
                        onClick={() =>
                          handleItemClick(subItem.route, subItem.text)
                        }
                      >
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <img
                            src={subItem.icon}
                            alt={`${subItem.text} icon`}
                            style={{
                              width: "24px",
                              height: "24px",
                              filter:
                                selectedRoute === subItem.route
                                  ? "invert(49%) sepia(83%) saturate(1839%) hue-rotate(102deg) brightness(97%) contrast(101%)"
                                  : "none",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={subItem.text}
                          primaryTypographyProps={{
                            fontFamily: "Nexa-Bold",
                            color:
                              selectedRoute === subItem.route
                                ? "rgba(0, 208, 89, 1)"
                                : "rgba(255, 255, 255, 1)",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
        <div onClick={handleLoginClick} className="Logout">
          <img src="/Images/Sidebarlogout/sidelog.svg" alt="Logout" />
          <p className="logoutPara">Log out</p>
        </div>
        </div>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: "rgba(3, 3, 3, 1)",
            height: "80px",
            borderBottom: "1px solid rgba(41, 41, 41, 1)",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            sx={{
              fontWeight: "800",
              fontFamily: "Nexa-Bold",
              fontSize: "24px",
              lineHeight: "36px",
              paddingLeft: "8px",
            }}
            noWrap
            component="div"
          >
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            height: "100%",
            display: { xs: "block", sm: "none" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "1px solid rgba(41, 41, 41, 1)",
              background: "rgba(3, 3, 3, 1)",
              borderRight: "1px solid rgba(41, 41, 41, 1)",
              height: "100vh",
              marginTop: "87px",
              position: "relative",
              overflowY: "auto",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            background: "rgba(3, 3, 3, 1)",
            borderRight: "1px solid rgba(41, 41, 41, 1)",
            border: "1px solid rgba(41, 41, 41, 1)",
            height: "100%",
            display: { xs: "none", sm: "block" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "rgba(3, 3, 3, 1)",
              borderRight: "1px solid rgba(41, 41, 41, 1)",
              marginTop: "87px",
              position: "relative",
              overflowY: "auto",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          overflow: "auto",
          flexGrow: 1,
          paddingLeft: "32px",
          // paddingRight: "64px",
          pt: { xs: 10, sm: 10 },
          background: "rgba(3, 3, 3, 1)",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          scrollbarWidth:"none",
          
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
