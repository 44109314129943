import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getOrganizer = createAsyncThunk("getOrganizer", async (data) => {
  try {
    const res = await api.get(`${API_URL}/auth/getAllOrganizers/?page=${data?.page}&search=${data?.search}`);
    return {
      status: res?.status,
      data: res?.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

